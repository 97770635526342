<template>
    <div class="newLoginbigbox">
        <svg class="stars" id="stars">
            <g v-for="star in stars" :key="star.index">
                <circle :cx="star.cx" :cy="star.cy" :r="star.r" :fill-opacity="star.opacity" :fill="star.color">
                </circle>
            </g>
        </svg>


        <div class="border-outter">
            <div class="border-inner">
                <div class="title" v-if="this.$i18n.locale == 'zh'">
                    {{ $t("basic.newLogin.title") }}
                </div>
                <div class="title" v-if="this.$i18n.locale == 'en'" style="font-size:22px">
                    {{ $t("basic.newLogin.title") }}
                </div>
                <div class="login">
                    <el-form style="width: 60%;" :model="SigninForm" ref="SigninForm" :rules="rules">
                        <el-form-item prop="UserName">
                            <div class="AccountNumber">
                                <el-input v-model="SigninForm.UserName" :placeholder='$t("basic.newLogin.userPla")'>
                                    <i slot="prefix" style="font-size: 22px;"
                                        class="el-input__icon el-icon-user-solid"></i>
                                </el-input>
                                <div class="shadow">

                                </div>
                            </div>
                        </el-form-item>

                        <el-form-item prop="PassWord">
                            <div class="password">
                                <el-input v-model="SigninForm.PassWord" :placeholder='$t("basic.newLogin.passwordPla")'
                                    show-password @keyup.enter.native="submitForm('SigninForm')">
                                    <i slot="prefix" style="font-size: 22px;height: 400px;"
                                        class="iconfont icon-suoding"></i>
                                </el-input>
                            </div>
                        </el-form-item>


                    </el-form>

                </div>
                <div class="loginBtn">
                    <div class="btn" @click="submitForm('SigninForm')">
                        {{ $t("basic.newLogin.login") }}
                    </div>
                </div>

                
            </div>

           
        </div>
        <a href="http://beian.miit.gov.cn/" target="_blank" class="copyright">
            版权所有@2024 深圳市恒玉杰科技发展有限公司 粤ICP备15103136号-5
        </a>
        <div class="langeBox" v-if="langeShow">
            <el-dropdown placement="bottom" @command="changeLangeuage">
                <div class="enBox">
                    <i class="iconfont icon-yuyan" style="font-size:35px;"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                    <el-dropdown-item command="en">EngLish</el-dropdown-item>

                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import gsap from 'gsap'
import messageAll from '@/public_Js/messageAll'
export default {
    components: {

    },
    data() {
        return {
            mtopic: "device/alarm/",
            stars: [],
            animation: {
                time: 0,
                star: null
            },
            bgObj: null,
            input: null,
            clearFun: true,
            // 账号密码
            SigninForm: {
                UserName: '',
                PassWord: '',
            },
            rules: {
                UserName: [
                    { required: true, message: '', trigger: 'blur' },
                ],
                PassWord: [
                    { required: true, message: this.$t("basic.newLogin.passwordRules"), trigger: 'blur' },
                    { min: 6, message: this.$t("basic.newLogin.passwordRulesLength"), trigger: 'blur' }
                ]
            },
            testList: [],
            langeShow: true,
        }
    },
    mounted() {

        if (!localStorage.getItem('theLanguage')) {
            this.changeLangeuage('zh')
        }
        this.rules.UserName[0].message = this.$t("basic.newLogin.userRules")
        // this.rules.PassWord[0].message =this.$t("basic.newLogin.passwordRules")
        // this.rules.PassWord[1].message =this.$t("basic.newLogin.passwordRulesLength")

        this.bgObj = document.getElementById('stars')
        this.randomStar()

    },
    destroyed() {
        this.clearFun = false
    },
    methods: {
        randomStar() {

            if (this.clearFun) {
                var timeline = gsap.timeline();

                timeline.to(this.animation, {
                    time: 1,
                    duration: 10,
                    repeat: -1,
                    onUpdate: () => {
                        if (this.clearFun) {
                            if (Math.random() > 0.6 && this.bgObj) {
                                this.animation.star = {
                                    cx: Math.random() * this.bgObj.width.animVal.value,
                                    cy: Math.random() * this.bgObj.height.animVal.value,
                                    r: Math.random() * 3,
                                    lifetime: Math.random() * 3,
                                    opacity: 1,
                                    xDir: 1,
                                    color: '#ffffff55'
                                    // color:getRandomColor()
                                };
                                this.stars.push(this.animation.star);
                            }

                            this.stars.forEach(star => {
                                star.r *= 1.01;
                                star.lifetime -= 0.005;
                                star.opacity -= 0.005;
                                star.cy -= 0.5;//Math.sin(Math.PI*2 * animation.time);
                                star.cx += 0.01;//Math.cos(Math.PI*2 * animation.time * 50);
                                // star.cx += 2 * star.xDir;
                                if (Math.random() > 0.85) star.xDir *= -1;
                                if (star.lifetime <= 0 || star.r > 30) {
                                    this.stars.splice(this.stars.indexOf(star), 1);
                                }
                            });
                        } else {
                            timeline.kill()
                        }
                    },
                    onComplete: () => {

                    }
                },

                );
            } else {
                return ''
            }
        },


        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },


        // 登录按钮
        submitForm(formName) {
            console.log("登录");
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let that = this
                    // 登录接口
                    let login = await this.$API.login.login.post({
                        loginname: that.SigninForm.UserName,
                        passwor: that.SigninForm.PassWord,
                        language: localStorage.getItem('theLanguage')
                    });

                    // 登录成功之后返回路由
                    if (login.data.code == 200) {

                        // messageAll.successOpen(login.data.message)
                        messageAll.successOpen(this.$t("basic.newLogin.loginSucc"))
                        // 把 token 传到 vuex, vuex 会进行本地存储持久化
                        this.$store.commit("setToken", login.headers["x-auth-token"]);
                        // 存储用户名 主页会用到用户名称
                        this.$store.commit("setLoginName", this.SigninForm.UserName);
                        // 
                        this.$store.commit("setUserRoleId", login.data.data.role);
                        console.log(login.data.data);
                        login.data.data.menu.unshift(this.$store.state.admingHome)
                        // 先把 路由 存储好后续跳转会用到
                        this.$store.commit('setMenu', login.data.data.menu);

                        this.$store.state.menu = login.data.data.menu



                        // 将大标题名字都带上去
                        let title = await this.$API.account_basic.loginNews.get({
                            language: localStorage.getItem('theLanguage')
                        })
                        this.$router.push({ name: 'ApplicationCenter' })
                        if (title.data.platform_title == '') {
                            title.data.platform_title = this.$t("basic.newLogin.platformTitle") //"安防后台管理"
                        }
                        if (title.data.entity_name == '') {
                            title.data.entity_name = this.$t("basic.newLogin.title") //"智慧安防云平台"
                        }
                        // 存入到本地
                        this.$store.commit('getAllTitle', title.data)

                        // 登录成功的MQTT
                        /* eslint-disable */
                        this.userName = localStorage.getItem("loginName")
                        if (this.userName != "") {
                            let topic = this.mtopic + this.userName
                            this.$CONFIG.MQTT_CLINET.subscribe(topic, (err) => {
                                if (!err) {
                                    console.log("APP.vue 订阅成功:" + topic);
                                }
                            });
                        }

                    } else if (login.data.code == 202) {
                        this.$router.push({ name: 'ApplicationCenter' })
                    } else if (login.data.code == 400) {
                        // messageAll.errorOpen(login.data.message)
                        messageAll.errorOpen(this.$t("basic.newLogin.loginLose"))

                    }
                } else {
                    return false;
                }
            });
        },


        // 切换语言
        changeLangeuage(val) {
            localStorage.setItem("theLanguage", val)
            location.reload();
        }

    }
}


</script>
<style lang="scss" scoped>
/* :root{
        --box-width:430;
        --box-height:540;
    } */

.newLoginbigbox {
    background-image: url('@/assets/img/HomePage/login_bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-clip: content-box;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;

    .langeBox {
        // width:60px;
        // height:60px;
        position: absolute;
        right: 30px;
        top: 30px;
        border-radius: 50%;
        border: 1px solid rgb(206, 231, 255);
        background: rgba(93, 163, 230, 0.3);

        i {
            color: rgb(129, 151, 172)
        }

        :hover {
            border-radius: 50%;
            background-color: rgba(93, 163, 230, 0.4);
            // i{
            //     color:rgba(27, 133, 233, 0.603)
            // }
        }

        .enBox {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            // border:1px solid rgb(206,231,255);
            // background:rgba(93, 163, 230, 0.3);
            cursor: pointer;

        }
    }
}

.stars {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 80vh;
}

@keyframes rotate1 {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.border-outter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    width: 620px;
   

}
.copyright{
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    background-color: rgba(6, 21, 52, .2);
    left: 0;
    text-align: center;
    text-decoration: none;
}

.border-inner {
    position: relative;
    align-items: center;
    margin-top: 150px;
    width: 500px;
    height: 500px;
    border-radius: 5px;
    z-index: 10;
    border: 2px solid rgb(46, 53, 155);
    background-color: rgba(30, 24, 87, 0.4);

    .title {
        width: 100%;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;

    }

    .login {
        width: 100%;
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;

        // justify-content: center;
        // background-color: aliceblue;
        .AccountNumber {
            width: 100%;
            height: 40px;
            position: relative;
            z-index: 100;
            margin-bottom: 30px;
            // background-color: aqua;
            box-shadow: 0px 15px 15px -7px rgba(54, 137, 252, 0.5);

        }

        .password {
            width: 100%;
            height: 40px;
            position: relative;
            z-index: 100;
            margin-bottom: 30px;
            // background-color: aqua;
            box-shadow: 0px 20px 15px -10px rgba(54, 137, 252, 0.5);

        }

        .shadow {
            width: 90%;
            // height: 50px;
            // box-shadow: 0px 20px 20px -0px rgba(54,137,252,1);
            // position: absolute;
            top: 5px;
        }
    }

    .loginBtn {
        width: 100%;
        height: 45px;
        display: flex;
        justify-content: center;

        .btn {
            width: 280px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background-color: rgba(40, 1, 204);
            border-radius: 12px;
            cursor: pointer;
            font-size: 15px;

            &:hover {
                background-color: rgba(40, 1, 204, 0.8);
                // cursor: pointer;
            }
        }
    }

    ::v-deep .el-input__inner {
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid rgba(54, 137, 252, 1);
        border-radius: 0;
        color: #ffffff;
        // box-shadow: 0px 10px 5px 0 rgba(54,137,252,1);
    }

    ::v-deep .el-input__prefix,
    .el-input__suffix {
        line-height: 40px;
    }

    ::v-deep .el-form-item {
        margin: 0;
    }

    ::v-deep .el-form-item__error {
        top: 65%;
    }


}
</style>
export default {
    basic: {
        // 公用
        public: {
            workbench: "切换工作台",
            clearSear: "清空查询条件",
            refresh: "刷新表格",
            search: "搜索",
            // 单位
            entitySearch: "单位名称搜索",
            entityID: "单位ID",
            entityName: "单位名称",
            entityAddr: "单位地址",
            // 下拉tble框中的场所
            siteID: "场所ID",
            siteName: "场所名称",
            other: "场所地址",
            siteSearchPla: "请输入场所名称",
            // 下拉tble框中的类型
            typeSearch: "请输入类型名称",
            typeID: "类型ID",
            typeName: "类型名称",
            typeOther: "类型编码",
            typeTitle: "设备类型",
            // 型号
            modelSearch: "请输入型号名称",
            modelID: "型号ID",
            modelName: "型号名称",
            modelOther: "型号代码",
            // 模型
            Two_modelSearch: "请输入模型名称",
            Two_modelID: "模型ID",
            Two_modelName: "模型名称",
            Two_modelOther: "创建时间",

            Unblock: "取消屏蔽",
            sureUnblock: "确定要取消屏蔽警告吗?",

            // 操作
            details: "详情",
            controls: "操作",
            confirmBtn: "确 定",
            cancel: "取 消",
            confirmNospace: "确定",
            cancelNospace: "取消",
            controls_collect: "收藏",
            cancelOperation: "取消操作",
            addBtn: "新增",
            delete: "删除",

            //  规则
            rulesSpace: "输入不能包含空格",
            rule_entityNospace: "单位ID不能为空",
            basicInformation: "基本信息",

            open: "开启",
            close: "关闭",
            closeSpace: "关 闭",
            Time_midden: "至",
            Time_start: "开始日期",
            Time_end: "结束日期",
            create_date: "创建日期",
            MobilePhone: "手机号码",
            sendingTime: "发送时间",
            eventType: "事件类型",
            deviceCoding: "设备编码",
            installAddr: "安装位置",
            alarmReason: "报警原因",
            alarmTiem: "报警时间",
            searchDeviceCoding: "请输入完整设备编码",
            moreSear: "更多搜索",
            owner: "业主",
            owner_phone: "电话",
            alarm_type: "报警类型",

            notAllowBatch: "不允许批量删除!",

            //messageAll
            message_addSucce: "新增成功!",
            message_addLose: "新增失败!",
            messageAllClear: "已清空所有查询条件!",
            messageAllRefresh: "刷新成功!",
            messageAllSear: "搜索成功!",
            message_collectSucc: "收藏成功!",
            message_collectLose: "收藏失败!",
            message_importSucc: "导出成功!",
            message_importLose: "导出失败!",
            message_importRule: "请先选择需要导出的数据",
            message_deleteImgSucc: "图片删除成功!",
            message_deleteImgLose: "图片删除失败!",
            message_ImgUpSucc: "上传成功!",
            message_ImgUpLose: "上传失败!",
            message_editSucc: "修改成功!",
            message_editLose: "修改失败",
            message_addDataSucc: "新增成功!",
            message_addDataLose: "新增失败!",
            message_deleteSucc: "删除成功!",
            message_deleteLose: "删除失败!",
            message_exceedMaxImg: "上传失败!只能上传一张照片",

            // 文件
            file_btnAdd: "点击上传",
            file_attachmentAdd: "附件上传",
            file_isUpIMg: "确定上传此图片吗?",
            file_Tips: "提示",


            // 表格
            derive: "导出",
            deriveTips: "确定要到导出这些数据吗？",
            table_createdTime: "创建时间",
            table_entity: "所属单位",
            table_deviceType: "设备类型",
            table_OwnersName: "业主姓名",
            table_OwnerTelephone: "业主电话",
            table_installationPersonnel: "安装人员",
            table_community: "社区",
            table_deviceModel: "设备型号",
            table_deviceState: "设备状态",
            table_deviceLatelyTime: "最近活动时间",
            table_useType: "使用类型",
            table_handleMan: "处理人",
            table_handleTime: "处理时间",
            table_handleSuggestion: "处理意见",
            table_FaultCause: "故障原因",
            table_faultTime: "故障时间",
            table_informant: "申报人",
            table_faultDesc: "故障描述",
            table_alarmRulesRadius: "预警范围",
            table_alarmRulesEvent: "预警事件",
            table_alarmRulesIsOpen: "是否开启",
            table_alarmRulesDevice: "预警设备",
            table_alarmRulesEntity: "预警单位",
            table_UpTime: "更新时间",
            table_pointName: "点位名称",
            table_pointAddr: "点位地址",
            table_relevanceDevice: "关联设备",
            table_entityName: "所属单位",
            table_region: "所属区域",

            // select的label
            select_all: "全部",
            select_Install: "已安装",
            select_uninstalled: "未安装",

            rule_content: "请输入内容",
            rule_data: "请选择数据",

            gas_vase: "煤气瓶",
            natural_gas: "天然气",
        },
        // 搜索的内容
        search: {
            sitePla: "请输入场所名称",
            typePla: "请输入类型名称",
            deviceCodePla: "请输入完整的设备编码",
            ownerPla: "请输入业主姓名",
            ownerPhonePla: "请输入业主电话",
            search: "搜索",
            searchNoMore: "没有更多了",
            isLoading: "正在加载",
        },
        // 省市县组件
        region: {
            city: "请选择省份",
            county: "请选择省或市",
            provincePla: "请选择省份",
            cityPla: "请选择市",
            countyPla: "请选择县"
        },

        // 应用中心
        ApplicationCenterPage: {
            title: "应用中心",
            logOut: "退出登录",
            backStage: "后台管理",
            NumScreen: "数字大屏",
            alarmPlatform: "接警平台",
            patrol: "巡检巡查",
            production: "生产管理",
            childPlatform: "子平台",
            logOutTips: "您确定退出登录吗?",
            chilListFiren: "智慧消防平台",
            chilListGas: "西乡燃气平台",
            chilListGasB: '通用燃气平台',
            yes: "好的",
            cancel: "取消"
        },
        // 登录页面
        newLogin: {
            title: "智慧安防云平台",
            login: "登录",
            userPla: "请输入账号",
            userRules: "账号不能为空",
            passwordPla: "请输入密码",
            passwordRules: "密码不能为空",
            passwordRulesLength: "长度不得低于6个字符",
            platformTitle: "安防后台管理",
            loginSucc: '登录成功!',
            loginLose: '用户名不存在或密码错误!',
        },
        // 数字大屏
        digitalPage: {
            title: "后台管理",

            deviceTitle: "设备统计",
            deviceAllNum: "设备总数",
            deviceOnLine: "在线设备数",
            deviceOffLIne: "离线设备数",
            deviceInstall: "已安装设备数",
            deviceNoInstall: "未安装设备数",
            leftMiddenTilt: "设备安装进度",
            leftMiddenNum: "数量",
            leftMiddenpercent: "百分比",

            leftBottomTitle: "设备类型统计",
            leftBottomDevice: "设备",

            middenTop_todayAlarm: "今日报警",
            middenTop_fault: "今日故障",
            middenTop_install: "今日安装",
            middenTop_maintenance: "今日维保",

            middenMap_owenr: "业主信息",
            middenMap_owenrPhone: "业主电话",
            middenMap_addr: "场所地址",
            middenMap_siteName: "场所名称",
            middenMap_siteDeviceNum: "场所设备总数",
            middenMap_floorNum: "楼层数",
            middenMap_dilogTitle: "点位列表",

            middenMap_dialog_owenrName: "业主姓名",
            middenMap_dialog_owenrPhone: "业主电话",
            middenMap_dialog_siteName: "场所名称",
            middenMap_dialog_siteAddr: "场所地址",

            middenBottom_entity: "单位管理",
            middenBottom_jianzhu: "建筑",
            middenBottom_device: "设备",
            middenBottom_user: "用户",
            middenBottom_sys: "设置",

            rightTop_alarm: "报警",
            rightTop_fault: "故障",

            rightMidden_title: "设备状态统计",
            rightMidden_offline: "离线",
            rightMidden_tamperd: "拆除",
            rightMidden_highTemperature: "高温",
            rightMidden_low_signal: "信号弱",
            rightMidden_lowBattery: "低电压",
            rightMidden_fault: "传感器故障",
            rightMidden_alarm: "报警",
            feifa_disassemble: "非法拆除",
            rengong_fault: "人工保障",
            rightBottom_alarmAddr: "报警地址",
            rightBottom_deviceNum: "设备编码",
            rightBottom_alarmType: "报警类型",

            dialog_entityTitle: "单位管理",
            dialog_entitySear: "请选择单位名称",
            dialog_entityName: "所属单位",
            dialog_entityAddr: "单位地址",
            dialog_entityPlatformName: "平台名称",
            dialog_entityGo: "查看设备",

            dialog_siteTitle: "建筑列表",
            dialog_siteSearName: "请输入场所名称",
            dialog_siteAddrSear: "请输入场所地址",
            dialog_siteAddr: "场所地址",
            dialog_siteName: "场所名称",
            dialog_siteEntityName: "所属单位",
            dialog_siteRegion: "所属区域",

            dialog_deviceNum: "请输入完整设备编码",
            dialog_deviceState: "请选择设备状态",
            dialog_deviceOrder: "指令",
            dialog_deviceNumTable: "设备编码",
            dialog_deviceEntity: "所属单位",
            dialog_deviceType: "设备类型",
            dialog_deviceInstallAddr: "安装位置",
            dialog_deviceTypetwo: "设备型号",
            dialog_deviceStateTable: "设备状态",
            dialog_deviceEventsTime: "最近活动时间",

            dialog_userTitle: "用户列表",
            dialog_userLoginName: "请输入账号",
            dialog_userNameSear: "请输入姓名",
            dialog_userPhoneSear: "请输入手机号",
            dialog_userEntityTitle: "单位",
            dialog_userEntityName: "所属单位",
            dialog_userPhone: "电话",
            dialog_userSex: "性别",
            dialog_userMan: "男",
            dialog_userWoMan: "女",
            dialog_userloginTable: "账号",
            dialog_userJurisdiction: "权限",
            dialog_userName: "用户名",
            dialog_userDeatil: "查看用户详情",
            dialog_userDeatilBasice: "基本信息",
            dialog_userDeatilBtn: "确认修改",


            dialog_SettingTitle: "设置",
            dialog_SettingBackdrop: "背景",
            dialog_SettingNormal: "标准颜色",
            dialog_SettingDarkblue: "极夜蓝",
            dialog_Settingblue: "靛青蓝",
            dialog_SettingGrey: "雅士灰",
            dialog_SettingDark: "幻影黑",
            dialog_SettingLight: "月光银",

            dialog_deviceListTitle: "设备列表",

        },

        // 设备列表
        deviceList: {
            search_isInstall: "是否安装",

            state_title: "设备状态统计",
            state_one: "全部状态",
            state_offline: "离线",
            state_tamperd: "拆除",
            state_highTemperature: "高温",
            state_low_signal: "信号弱",
            state_lowBattery: "低电量",
            state_fault: "传感器故障",
            state_alarm: "报警",
            state_normal: "正常",
            state_batteryState: "电池状态",
            state_valveState: "阀门状态",


            device_detailTitle: "设备详情",
            device_detailBasice: "基本信息",
            device_detailInstall: "安装信息",
            device_detailRun: "设备运行数据",
            device_detailAlarm: "报警处理",
            device_detailFault: "故障处理",
            device_detailVideo: "视频监控",
            device_detailAlarmRule: "预警规则",
            device_detailLInkage: "联动规则",

            device_bind: "绑定",
            device_bindTitle: "绑定设备",
            deviceBind_Pla: '选择已创建场所,或跳过此输入,下方创建新场所。',
            // 屏蔽
            device_blockDevice: "屏蔽",
            device_recogniseBlockDevice: "确定要屏蔽该设备报警吗?屏蔽后您将不再收到该设备的报警弹窗提示!",
            device_SuccessfullyBlockedTheDevice: "成功屏蔽该设备!",
            // 指令
            device_order: "指令",
            device_orderTilte: "指令下发",
            device_orderTips: "您确定要下发此指令吗？",
            device_orderNoList: "此设备暂无指令",
            device_noRunOrder: "此设备没有可以下发的指令",
            device_noOrder: "此设备没有指令!",
            devcie_orderSucc: "指令下发成功!",
            device_orderLose: "指令下发失败!",
            // 更多操作
            device_controlsTitle: "更多操作",
            device_controlsInitialize: "初始化设备",
            device_controlsTransfer: "设备转移",
            device_controlsRelieveBind: "解除绑定",
            device_controlsClearFault: "清除故障",
            device_controlsFang: "布防",
            device_controlsCefang: "撤防",
            device_controlsLock: "锁定",
            device_controlsRelieveLock: "解除锁定",
            device_controlsInitializeCont: "删除设备关联的点位,恢复未绑定状态",
            device_controlsTransferCont: "将设备转移到其他单位",
            device_controlsRelieveBindCont: "解除当前账号与设备的绑定关系",
            device_controlsClearFaultCont: "清除设备的所有故障",
            device_controlsFangCont: "布防设备,支持全部设备",
            device_controlsCefangCont: "撤防设备,支持全部设备",
            device_controlsLockCont: "锁定",
            device_controlsRelieveLockCont: "解除锁定",
            device_DispatchWorkOrders: "派发工单",


            device_messgae: "请选择您要操作的设备!",
            device_controlsInitializeTips: "此操作将会删除设备关联点位,并恢复未绑定状态, 是否继续?",
            device_controlsInitializeSucc: "初始化成功!",
            device_controlsInitializeLose: "初始化失败!",

            device_controlsRelieveBindTips: "此操作将会解除当前账号与设备的绑定关系, 是否继续?",
            device_controlsRelieveBindSucc: "设备解绑成功!",
            device_controlsRelieveBindLose: "设备解绑失败!",

            device_controlsClearFaultTips: "此操作将会清除设备的所有故障, 是否继续?",
            device_controlsClearFaultSucc: "清除设备所有故障成功!",
            device_controlsClearFaultLose: "清除设备所有故障失败!",

            device_controlsFangTips: "设备,此操作目前只支持门磁/红外烟感,是否继续",
            device_succ: "成功!",
            device_lose: "失败!",
            device_cancel: "已取消",
            device_warn: "警报",

            device_controlsLockTips: "锁定设备后,将不能在处理设备的故障数据,只能处理报警和心跳数据!您确认要锁定设备?",
            device_controlsLockDevice: "锁定设备",
            device_controlsRelieveLockTDevice: "解锁锁定",
            device_controlsRelieveLockTips: "解除锁定,设备恢复正常功能,您确认要解除锁定?",

            device_controlsTransferContSucc: "设备转移成功!",
            device_controlsTransferContLose: "设备转移失败!",

            device_collectTips: "确定要收藏设备吗?",

            device_noInstall: "设备未安装!",
            device_noCheckDeatil: "无法查看设备详情!",
            device_checkLose: "查询设备详情失败!",
            device_Novideo: "获取播放地址失败！",

            // drawer-侧边弹出框
            drawer_title: "设备详情",
            drawer_installNews: "安装信息",
            drawer_runData: "设备运行数据",
            drawer_alarmHandle: "报警处理",
            drawer_faultHandle: "故障处理",
            drawer_video: "视频监控",
            drawer_alarmRule: "预警规则",
            drawer_linkageRule: "联动规则",
            drawer_pushCondition: "推送情况",
            drawer_BindUser: "绑定人员",
            drawer_eventImage: "事件图片",

            // dialog-中心弹出框
            dialog_title: "设备转移",
            enpty_title: "请选择单位名称!!!",

            // 基本信息
            basice_Title: "设备信息",
            basice_TwoCode: "二维码",
            basice_installTime: "安装时间",
            basice_deviceType: "设备类型",
            basice_model: "型号代码",
            basice_code: "设备代码",
            basice_edit: "修改",
            basice_ccid: "ccid",
            basice_telecomID: "电信ID",
            basice_moveID: "移动ID",
            basice_imei: "imei",
            basice_imsi: "imsi",
            basice_ToggleChart: "切换图标",
            basice_toggleCar: "切换卡片",
            basice_deviceStats: "设备属性",
            basice_signalStrength: "信号强度",
            basice_voltage: "电压",
            basice_temperatureNum: "温度值",
            basice_concentrationNum: "浓度值",
            basice_deviceIMg: "设备图片",
            basice_upImg: "上传图片",
            basice_deleteImgTips: "您确定要删除设备图片吗?",
            basice_chartTitle: "设备属性趋势",
            basice_temperature: "温度",
            basice_concentration: "浓度",
            basice_chartDeviceNoData: "该设备没有上报数据，无法展示属性曲线！",
            basice_copySucc: "复制成功!",
            basice_copyLose: "抱歉,复制失败!",
            basice_editSucc: "修改成功!",
            basice_editLose: "修改失败!",
            basice_humidness: "湿度",
            basice_HumidityValue: "湿度值",
            basice_brightScreen: "亮屏",
            basice_noScreen: "熄屏",
            basice_TemperatureUnit: "温度单位",
            basice_DegreeCelsius: "摄氏度",
            basice_Fahrenheit: "华氏度",
            basice_ScreenState: "屏幕状态",

            basice_alarm: "报警",
            basice_cameraArgu: "摄像头参数",
            basice_addr: "地址",
            basice_state: "状态",
            basice_line: "在线",
            basice_transmit: "流传输模式",
            basice_NewHeartbeat: "最近心跳",
            basice_NewSignIn: "最近注册",
            basice_passage: "通道数",
            basice_bing: "设备绑定",
            basice_editMqtt: "修改mqtt信息",
            basice_editHttp: "修改http信息",
            basice_smoke: "433烟感",
            basice_relieveBind: "解除绑定",
            basice_relieve: "解绑",
            basice_setFang: "设置防区",
            basice_fang: "防区",
            basice_confirmEdit: "确定要修改吗?",
            basice_ipTips: "请输入Ip地址/域名",
            basice_userNameTIps: "请输入用户名",
            basice_password: "请输入密码",
            basice_bindNameTips: "请输入绑定名称",
            basice_fangqu: "请输入防区名称",
            basice_messageCarmes: "获取摄像头详情失败!",
            basice_messageDeviceNodata: "该设备没有上报数据,无法展示属性曲线",
            basice_messageAddr: "地址不能为空!",
            basice_messageIssueSucc: "下发成功!",
            basice_messageIssueLose: "下发失败!",
            basice_messageNOfang: "防区名称不能为空",
            basice_messageIssueSetSucc: "下发设置防区成功!",
            basice_messageIssueSetLose: "下发设置防区命令失败!",
            basice_messageIssueBind: "下发绑定命令失败!",
            basice_messageCarmesNoSmoke: "当前摄像没有挂载烟感!",
            basice_messageInquireLose: "查询失败",
            basice_messageInquire433Lose: "此设备暂无烟感!",
            basice_messageIssueORderLose: "下发命令失败!",
            basice_messageNoBindLose: "下发解绑指令失败!",

            basice_deviceVoltage: "设备电压",
            basice_temperatureNew: "现场温度",
            basice_formHUmidness: "相对湿度",
            basice_MoverNum: "移动次数",
            basice_signalQuality: "信号质量",
            basice_deviceIMSi: "设备IMSI",
            basice_getDeviceDetilTips: "获取设备详情失败!",

            // basice_



            // 安装信息
            install_title: "场所-点位信息",
            install_checkSiteImg: "查看场所图片",
            install_owner: "业主",
            install_ownerPhone: "业主电话",
            install_pointName: "点位名称",
            install_pointCont: "查看点位图片",
            install_pointAddr: "点位地址",
            install_siteImg: "场所图片",
            install_mapAddr: "地图位置",
            install_copyLatlng: "复制场所经纬度",
            install_getSiteNewsLose: "获取场所信息失败",
            install_getPointNewsLose: "获取点位信息失败",

            // 运行数据
            operation_alarmTitle: "报警数据",
            operation_voltage: "电压(mv)",
            operation_dataSource: "数据来源",
            operation_otherData: "其他数据",
            operation_firmCoding: "厂商编码",
            operation_pushModel: "推送型号",
            operation_pushType: "推送类型",
            operation_pagination: "当前页码",
            operation_faultTitle: "故障数据",
            operation_hearbeat: "心跳数据",
            operation_releaseRecord: "指令下发记录",
            operation_previous: "上一页",
            operation_NextPage: "下一页",
            operation_resultCoding: "下发结果编码",
            operation_resultDesc: "下发结果描述",

            // 报警处理
            alarm_handle: "处理",
            alarm_succ: "处理成功!",
            alarm_dialogTitle: "处理报警",
            alarm_handlePla: "请选择报警原因",
            alarm_systematicPro: "系统生成",
            alarm_faultHandle: "处理故障",
            alarm_isFinishMonad: "是否结单",
            alarm_yes: "是",
            alarm_no: "否",
            alarm_feedback: "反馈内容",
            alarm_feedbackPla: "请输入反馈内容",
            alarm_impor: "导入文件",
            alarm_draweTitle: "故障详情",
            alarm_faultType: "故障类型",
            alarm_handleRecords: "处理记录",
            alarm_handleImg: "处理图片",
            alarm_ImgCheck: "图片详情",
            alarm_MessageChcekLose: "查看失败!",

            // 绑定
            bind_entity: "单位",
            bind_installSite: "安装场所",
            bind_siteName: "场所名称",
            bind_siteNameLian: "关联场所",
            bind_installPoint: "安装点位",
            bind_installPointPla: "请输入安装点位",
            bind_isntallAddr: "安装地址",
            bind_installAddrPla: "请输入安装地址",
            bind_subBind: "立即绑定",
            bind_selectRegion: "请选择所属区域",
            bind_siteNameLianPla: "请输入关联场所",
            bind_addSite: "新增场所",
            bind_siteNamePla: "请输入场所名称",
            bind_siteAddr: "请输入场所地址",
            bind_owner: "请输入业主姓名",
            bind_ownerPhone: "请输入业主电话",
            bind_floorNum: "楼层数",
            bind_dialogTitle: "设置场所地图位置",
            bind_ruleSite: "请选择场所",
            bind_ruleSiteLength: "长度在 5 到 50 个字符",
            bind_pointName: "请填写点位名称",
            bind_pointLength: "长度在 1 到 20 个字符",
            bind_pointAddr: "请填写点位地址",
            bind_pointaddrLength: "长度在 1 到 100 个字符",
            bind_ruleMap: "请输入设置地图位置",
            bind_messageSucc: "绑定成功!",
            bind_messageLose: "绑定失败!",
            bind_foundSucc: "创建成功!",
            bind_foundLose: "创建失败!",

            // 预警规则
            alarmRule_OpenState: "开启状态",
            alarmRule_isOpen: "是否开启",
            alarmRule_event: "事件类型",
            alarmRule_productRank: "产品级别",
            alarmRule_entityRank: "单位级别",
            alarmRule_siteRank: "场所级别",
            alarmRule_deviceRank: "设备级别",
            alarmRule_openState: "您确定要修改预警规则开启状态吗？",
            alarmRule_drawerTitle: "关联用户",
            alarmRule_sysUser: "系统用户",
            alarmRule_TemUser: "临时用户",

            // 联动设备
            linkRule_linkDeviceCode: "请输入完整联动设备编码",
            linkRule_linkDevice: "联动设备",
            linkRule_triggerDevice: "触发设备",
            linkRule_triggerEvent: "触发事件",
            linkRule_linkeOrder: "联动指令",
            linkRule_allState: "全部状态",

            // 推送情况
            push_aliPhoneRecords: "阿里云电话记录",
            push_linePhoneRecords: "专线电话记录",
            push_aliSmSRecords: "阿里云短信记录",
            push_appPush: "APP推送记录",

            // 绑定人员
            bindUser_Title: "绑定人员",
            bindUser_userName: "用户名",
            bindUser_isFirstBind: "是否首个绑定",
            bindUser_firstBind: "首个绑定",
            bindUser_ISControls: "是否有操作权限",
            bindUser_canControls: "可操作",
            bindUser_noCOntrols: "不可操作",
            bindUser_Tips: "您确定要修改操作权限吗？",
            bindUser_editRole: "修改权限",

            basice_DetectorData: "探测器数据",
            basice_DetectorNumber: "探测器编号",
            basice_SensorType: "传感器类型",
            basice_report: "上报周期",
            basice_4gGas: "4g工业燃气类型",
            basice_wireLess: "无线模块类型",
            edition: "版本",

            device_allNnum: "设备总数",
            OwnDevice: "有设备",
            NoDevice: "无设备",
            siteImg: "场所图片",
            pointIMg: "点位图片"
        },

        // 用户
        userManage: {
            // 用户信息基本看到
            details_edit: "修改",
            details_userNewsTitle: "用户信息",
            details_saveEdit: "保存修改",
            details_editPawword: "修改密码",
            details_loginName: "账号",
            details_name: "姓名",
            details_phone: "联系电话",
            details_sex: "性别",
            details_roleID: "角色",
            details_Entity: "所属单位",
            details_limitsName: "权限",
            details_createTime: "创建时间",
            details_upDateTime: "更新时间",
            details_WeChatOfficialID: "微信公众号ID",
            details_WechatID: "微信ID",
            details_isLogin: "账号是否登录",
            details_Mna: "男",
            details_woman: "女",

            // 用户开关信息
            details_userSwitchTitle: "用户开关信息",
            details_isLoginApp: "是否允许登录App",
            details_isReceivePush: "是否接收APP端报警推送",
            details_isLoginPc: "是否允许登录PC",
            details_isReceiveSMS: "是否接收短信",
            details_isREceivePhon: "是否接收电话",
            details_isReceiveOfficial: "是否接收公众号推送",
            details_isPcAlarmPush: "是否接收PC端警报推送",
            details_isLoginProgram: "是否允许登录小程序",
            details_appPush: "是否接收APP端报警推送",
            // 用户信息,未输入时提醒
            details_loginNamePla: "请输入账号",
            details_namePla: "请输入姓名",
            details_phonePla: "请输入联系电话",
            details_sexPla: "请选择性别",
            details_roleIDPla: "请选择角色",
            details_EntityPla: "请选择所属单位",
            details_limitsNamePla: "请输入权限名称",
            details_WeChatOfficialIDPla: "请输入微信公众号ID",
            details_WechatIDPla: "请输入微信ID",
            details_isLoginPla: "请选择账号是否登录",

            // 用户信息校验提醒
            details_loginNameRule: "账号不能为空",

            details_nameRule: "姓名不能为空",
            details_phoneRule: "联系电话不能为空",
            details_phoneRuleTwo: "请输入正确的联系电话",
            details_roleNoSp: "角色不能为空",
            details_sexRule: "性别不能为空",
            details_pawword: "密码不能为空",
            details_pawwordTwo: "密码必须是字母和数字下划线组成",
            details_login_Name: "账号不能为空",
            details_login_name_two: "账号必须是字母和数字组成",
            details_pawwordThree: "密码必须保持在6-18位字符",
            details_pawwordFour: "请输入密码",
            details_pawwordfive: "请再次输入密码",
            details_pawwordsix: "两次输入的密码不一致!",
            details_EntityRule: "所属单位不能为空",
            details_isLoginRule: "账号是否登录不能为空",

            // 用户开关信息,未输入时提醒
            details_isLoginAppPla: "请选择是否允许登录App",
            details_isReceivePushPla: "请选择是否接收PP报警端推送",
            details_isLoginPcPla: "请选择是否允许登录PC",
            details_isReceiveSMSPla: "请选择是否接收短信",
            details_isREceivePhonPla: "请选择是否接收电话",
            details_isReceiveOfficialPla: "请选择是否接收公众号推送",
            details_isPcAlarmPushPla: "请选择是否接收PC端警报推送",
            details_isLoginProgramPla: "请选择是否允许登录小程序",

            // 用户开关信息,校验提醒
            details_isLoginAppRule: "是否允许登录App不能为空",
            details_isReceivePushRule: "是否接收APP报警推送不能为空",
            details_isLoginPcRule: "是否允许登录PC不能为空",
            details_isReceiveSMSRule: "是否接收短信不能为空",
            details_isREceivePhonRule: "是否接收电话不能为空",
            details_isReceiveOfficialRule: "是否接收公众号推送不能为空",
            details_isPcAlarmPushRule: "是否接收PC端警报推送不能为空",
            details_isLoginProgramRule: "是否允许登录小程序不能为空",

            details_confimEdit: "确定修改",

            user_roleID: "角色ID",
            user_rolePla: "角色搜索",
            user_sexSearPla: "性别搜索",
            user_moreSear: "更多搜索",
            user_entityNamePla: "请输入单位名称",
            user_confirmPaword: "确认密码",
            user_addUser: "新增用户",
            user_cipher: "密码",
            user_submit: "提交",
            user_role: "角色",

            user_sureDelUser: "确定删除该用户吗？",
            user_notDelOwn: "不支持删除自己的操作",
            user_delSuccess: "删除成功",
            user_delError: "删除失败",
        },

        // 接警平台
        alarmPlatform: {

            warchMan: "值班人",
            connectState: "连接状态",
            succeed: "成功",
            lose: "失败",
            deviceAllData: "设备总数",
            todayAlarm: "今日报警次数",
            pushcondition: "推送情况",
            alarmHandleHandrail: "接警处理栏",
            deviceBasiceNews: "设备基本信息",
            deviceData: "设备数据",
            alarmReason: "报警原因",
            alarmReasonPla: "请选择报警原因",
            remark: "备注",
            handleAlarm: "处理报警",
            rest: "重置",
            leisure: "空闲",
            alarmRecords: "报警记录",
            video: "视频",

            table_deviceNumber: "设备编号",
            table_alarmTime: "报警时间",
            table_address: "安装位置",
            deviceModelNum: "设备型号",
            deviceType: "设备类型",
            entity: "所属单位",


            handle: "处理",
            untreatedNews: "未处理信息",
            dutyLog: "值班日志",
            close: "关闭",

            planeImg: "平面图",
            TwoDMap: "二维地图",
            threeDMap: "3D地图",
            threeDModeling: "3D模型",
            videoWatch: "视频监控",
            relevanceVideo: '设备-关联视频',
            dragCom: '长按拖拽',

            timePushRecords: "当前时间推送记录",
            AliSmS: "阿里云短信",
            AliVoice: "阿里云语音",
            PruvateTelephone: "专线电话",
            appPush: "APP个推",

            Mess_selectAlarm: "请先选择报警事件!",
            mess_handleAlarmLose: "处理报警失败!",
            mess_handleAlarmSuccess: "处理报警成功!",
            mess_handleNewAlarm: "请先处理当前报警!",
            mess_noVideo: "摄像头设备,单未关联设备!",
            rulesHandleWay: "请选择处理方式",
            rulesRemark: "请输入备注",

            selectKey: "按键测试",
            selectSmoke: "烟雾测试",
            selectGas: "燃气测试",
            selectReharsal: "演练",
            selectFireHazard: "火灾",
            selectOther: "其他",
            selectAll: "全部",
            selectUnconfirmed: "未确认",

            // 报警记录
            alarmRecord_btn: "返回设备报警记录",
            alarmRecord_searSiteName: "请输入场所名称",
            alarmRecord_SearTypeName: "请输入类型名称",
            alarmRecord_alarmAllNum: "报警总数",
            alarmRecord_todayAlarm: "今日报警",
            alarmRecord_processor: "处理人",
            alarmRecord_handleTime: "处理时间",
            alarmRecord_handlingSuggestion: "处理意见",
            alarmRecord_histouricalRecord: "历史记录",
            alarmRecord_handleTitle: "处理报警",
            alarmRecord_selectPla: "请选择报警原因",
            alarmRecord_alarmReason: "请输入报警备注",
            alarmRecord_handlecomplete: "处理完成!",
            alarmRecord_handleLose: "处理失败!",
            alarmRecord_check_TypeMessage: "请选择报警原因",
            alarmRecord_alarmRemarks: "请输入报警备注",

            // 值班日志
            dutyLog_add: "新增",
            dutyLog_FillMan: "填报人",
            dutyLog_FillContent: "填报内容",
            dutyLog_attachmentsOne: "附件1",
            dutyLog_attachmentsTwo: "附件2",
            dutyLog_attachmentsThree: "附件3",
            dutyLog_download: "下载",
            dutyLog_empty: "空",
            dutyLog_DialogTitle: "新增日志",
            dutyLog_addFormLabel: "填报内容",
            dutyLog_addFormPla: "请输入填报内容",
            dutyLog_addFormRule: "填报内容不能为空",

            message_alarmLose: "获取播放地址失败!",
            message_alarmLoseAndGuanlian: "获取关联摄像头播放地址失败!",
            message_alarmLoseNOvide: "获取播放地址失败!且无关联摄像头",
            message_plangImgLose: "此报警暂无平面图",
            message_noRelevanc: "未关联摄像头",
            message_alarmBlockleisure: "接警栏空闲,无法查看",
            // message_noRelevanc:"未关联摄像头",

        },

        // 推送记录
        pushRecords: {
            public_time: "调用时间",
            public_pushMeassger: "推送内容",
            public_deviceNum: "设备统一编码",

            AliSmsPhone: "手机号码",
            AliSmsTime: "发生时间",
            AliSmsEventType: "事件类型",
            AliSmsEvent_alarm: "报警事件",
            AliSmsEvent_fault: "故障事件",
            AliSmsEvent_heartbeat: "心跳事件",
            AliSmsEvent_deviceOffLine: "设备离线事件",
            AliSmsEvent_deviceOnLine: "设备上线事件",
            AliSms_newMessage: "信息内容",

            // 语音推送
            AliVoiceTime: "调用时间",
            AliVoicePush: "推送内容",

            // 公众号
            publicAccount_template: "模板ID",
            publicAccount_pushUser: "推送用户ID",
            publicAccount_errmsg: "结果描述",
            publicAccount_msgid: "消息ID",
            publicAccount_deviceNum: "设备统一编码",
            publicAccount_ruleId: "规则ID",

            // 专线
            specialLine_answerState: "接听情况",
            specialLine_userNoAnswer: "用户未接听",
            specialLine_refuseAnswer: "用户拒绝接听",
            specialLine_answerButFinish: "用户接听且主动挂断",
            specialLine_answerButPassivityFinish: "用户接听且被动挂断",
            specialLine_answerTIme: "接听时间",
            specialLine_noAnswer: "未接听",
            specialLine_overTime: "结束时间",

            // app推送
            appPush_pushResult: "推送结果",
            appPush_pushRState: "推送状态",

            // 阿里云电话记录
            aliPho_phoneSearPla: "输入电话搜索",
            aliPho_rulesPhone: "请输入11位的电话格式"
            // aliPho_
            // aliPho_
            // aliPho_
            // aliPho_
        },

        // 事件类型
        eventType: {
            eventTitle: "事件类型",
            eventIsOpen: "事件开关",
            alarmEvent: "报警事件",
            faultEvent: "故障事件",
            heartbeatEvent: "心跳事件",
            OffLineEvent: "设备离线事件",
            OnLineEvent: "设备上线事件",
        },

        // 设备管理-故障记录
        faultRecords: {
            backFaultRecords: "返回设备故障记录",
            handleStatePla: "请选择处理状态",
            handleDeviceState: "请选择设备状态",
            historicalRecord: "历史记录",
            handle: "已处理",
            noHandle: "未处理"
        },

        // 设备管理-报警记录
        alarmRecirds: {
            backAlarmRecords: "返回设备报警记录",
            selectAlarmPla: "请选择报警原因",
            pushState: "推送情况",
            dialogTitle: "处理报警",
            alarmCausePla: "请选择报警原因",
            AllAlarmCause: "全部报警原因",
            pushRecords: "推送记录",
            isCollectTips: "确定要取消收藏设备吗?",
            cancelCollect: "取消收藏",
            message_cancelCollectSucc: "取消收藏成功!",
            message_cancelCollectLose: "取消收藏失败!",
        },


        // 安装管理-安装点管理
        installPoint: {
            pointName: "点位名称",
            pointNamePla: "请输入点位名称",
            deviceCoding: "设备编码",
            deviceCodingPla: "请输入完整设备编码搜索",
            pointAddrPla: "请输入点位地址搜索",
            installNews: "安装点信息",
            saveEdit: "保存修改",
            verifyEdit: "确认修改",
            collectPointTips: "确定要收藏安装点吗?",
            colloect: "收藏",
            drawerTitle: "安装点详情",
            dialog: "新增安装点",
            rules_noSpecial: "不能含有特殊字符!",

            form_deviceNum: "设备编码:",
            form_deviceNumPla: "请输入设备编码",
            form_floorNum: "楼层数:",
            form_floorNumPla: "请输入楼层数",
            form_pointAddrPla: "请输入点位地址",
            form_entity: "所属单位:",
            form_pointAddr: "点位地址:",
            form_siteName: "场所名称",
            form_PointName: "点位名称:",
            form_entityPla: "请选择所属单位",
            form_naem: "名称",
            message_succ: "收藏安装点成功!",
            message_lose: "收藏安装点位失败!",
            // form_

            img_title: "安装点图片信息",
            img_deleteTips: "确定要删除此图片吗?",
            img_messageSucc: "图片上传成功!",
            img_messageLose: "图片上传失败!",
            img_messageCancel: "已取消上传",
            img_tips: "请先确认或取消当前点位!",
            img_pointSucc: "点位保存成功!",
            img_pointLose: "点位保存失败!",
            point_delete_tips: "删除点位，关联的设备恢复为未绑定状态，点位图片等相关数据也会删除，您确定要删除点位吗？",
        },

        // 安装管理-场所管理
        siteManage: {
            search_site: "场所名称搜索",
            search_siteAddr: "场所地址搜索",
            addBtn: "新增",
            site_collectTips: "确定要收藏此场所吗?",
            site_drawerTitle: "场所详情",
            site_news: "场所信息",
            site_contactPerson: "联系人:",
            site_contactPersonPhone: "联系人电话:",
            site_log: "经度:",
            site_lng: "纬度:",
            site_contactPersonName: "联系人姓名:",
            site_contactPersonNamePla: "请输入联系人姓名",
            site_contactPersonNum: "联系电话",
            site_contactPersonNumPla: "请输入联系电话",
            site_imgNews: "图片信息",
            site_contactPersonPla: "请输入联系人",
            site_contactPersonPhonePla: "请输入联系人电话",
            site_siteAddr: "场所地址",
            site_logPla: "请输入经度",
            site_lngPla: "请输入纬度",
            site_ruleSiteName: "场所名称不能为空",
            site_ruleSiteAddr: "场所地址不能为空",
            site_ruleContactName: "联系人姓名不能为空",
            site_rulePhone: "联系人电话不能为空",
            site_rulePhoneTwo: "新增数据失败!注意电话格式!",
            site_modelID: "模型ID",
            site_modelNnme: "模型名称",
            site_modelCreateTime: "创建时间",
            site_modelNamePla: "请输入模型名称",
            site_collectSucc: "收藏场所成功!",
            site_collectLose: "收藏场所失败!",
            site_editSucc: "修改点位成功!",
            site_editLose: "修改点位失败!",
            site_cancelAddSite: "已取消新增场所!",
            site_delete_tips: "删除场所后，场所下的点位和楼层也会同步删除，关联的设备也会恢复为未绑定状态，您确认要删除场所吗？",
        },

        // 安装管理_楼层管理
        floorManage: {
            floor_collectTips: "确定要收藏此楼层吗?",
            floor_drawer: "楼层详情",
            floor_news: "楼层信息",
            floor_dialogTItle: "新增楼层",
            floor_createTIme: "创建时间:",
            floor_floor: "楼层:",
            floor_floorPla: "请输入楼层",
            floor_messageSucc: "收藏楼层成功!",
            floor_messageLose: "收藏楼层失败!",
            floor_floorNum: "楼",

            add_floor: "楼层",
            add_site: "所属场所",
            add_reset: "重置",
            add_rulerNum: "请输入数字值",
            add_flooRules: "楼层不能为空",
            add_siteRules: "所属场所不能为空",

            img_addSucc: "上传图片成功!",
            img_addLose: "上传图片失败!",
            floor_delete_tips: "您确定要删除楼层吗？",
        },

        // 收藏夹-楼层收藏
        collectFloorManage: {
            floor_numPla: "楼层数搜索",
            floor_cancelCollectTips: "确定取消收藏此楼层吗?",
            floor_uncollect: "取消收藏",
            floor_dialogTitle: "新增设备详情",
            floor_createdTimePla: "请输入创建时间",
            floot_floorNum: "",
            floor_siteNamePla: "请选择场所名称",
            floor_siteAddrPla: "场所地址",
            message_uncollectSucc: "取消收藏楼层成功!",
            message_uncollectLose: "取消收藏楼层失败!",
            message_floorNoSpeac: "楼层不能为空!"
        },

        // 收藏夹-安装点收藏
        collectPoint: {
            point_searchName: "点位名称搜索",
            point_collectTips: "确定要取消收藏点位吗?",
            point_dialogTitle: "新增安装点",
            point_relevanceSite: "关联场所",
            point_uncollectSucc: "取消安装点成功!",
            point_uncollectLose: "取消安装点失败!",
        },

        // 收藏夹-场所收藏
        collectSite: {
            site_collectTips: "确定要取消收藏场所",
            site_cancelCollect: "取消收藏",
            site_rulesLon: "经度不能为空",
            site_rulelat: "纬度不能为空",
            stie_cancelSiteSucc: "取消收藏场所成功!",
            site_cancelSiteLose: "取消收藏场所失败!",

            basic_deleteImgTips: "确定要删除此图片吗?",
            basic_UpImgSucc: "图片上传成功!",
            basic_UpImgLose: "图片上传失败!",
            basic_cancelUp: "已取消上传!",

            map_TiTle: "地图信息",
            map_editPoint: "修改点位",
            map_confirmPoint: "保存",
            map_lon: "经度",
            map_lng: "纬度",
            map_siteNamePla: "输入场所名称进行查询",
            map_tips: "您可以移动蓝色水滴,将其移动到您需要的位置",
        },

        // 生产管理-设备模型
        deviceProfile: {
            profile_modelName: "模型名称",
            profile_modelNamePla: "请输入模型名称",
            profile_enttity: "单位名称",
            profile_entityPla: "请输入单位名称",
            profile_tableModelName: "模型名称",
            profile_drawerTitle: "设备模型详情",
            profile_basiceLabel: "基本信息",
            profile_dialogTitle: "新增设备模型",

            addForm_IDPla: "请输入ID",
            addForm_appkeyPla: "请输入aep_appkey",
            addForm_appsecretPla: "请输入aep_appsecret",
            addForm_product_idPla: "请输入product_id",
            addForm_command_idPla: "请输入command_id",
            addForm_masterkey: "请输入masterkey",
            addForm_protocol_type: "请输入protocol_type",
            addForm_protocol_typeRule: "protocol_type不能为空",

            addForm_expire_time: "指令缓存时间",
            addForm_expire_timePla: "请输入指令缓存时间",
            addForm_onenet_apikeyPla: "请输入onenet_apikey",
            addForm_BC260Y: "BC260Y设备",
            addForm_BC260YPla: "请输入BC260Y设备",
            addForm_Platform: "运营商平台",
            addForm_PlatformPla: "请输入运营商平台",
            addForm_creator: "创建人",
            addForm_creatorPla: "请输入创建人姓名",
            addForm_onenerPla: "请输入移动onener",

            rules_modelNameNoSpace: "模型名称不能为空",
            rules_modelNameLength: "长度不能超过20",
            rules_maxLength: "长度不能超过50",
            rules_entityNoSpace: "所属单位不能为空",
            rules_platform: "运营商平台不能为空",

            message_entity: "单位不能为空!",
            message_addDeviceSucc: "新增设备模型成功!",
            message_addDeviceLose: "新增模型失败!",


        },

        // 生产管理-设备型号
        deviceModel: {
            modelName: "型号名称",
            model_NamePla: "请输入型号名称",
            table_modelName: "设备名称",
            table_model: "所属模型",

            model_drawer: "设备型号详情",
            model_dialog: "新增设备型号",

            rules_modelNoVacancy: "设备型号不能为空",
            form_deviceID: "设备型号ID",
            form_deviceIDPla: "请输入设备型号ID",
            form_modelCodingPla: "请输入型号代码",
            form_modelIntro: "型号简介",
            form_modelIntroPla: "请输入型号简介",
            form_deviceModel: "设备模型",
            form_LowVoltage: "最低电压",
            form_LowVoltagePla: "请输入最低电压",
            form_LowVoltageNum: "低电压次数",
            form_LowVoltageNumPla: "请输入低电压次数",
            form_lowSignal: "最低信号值",
            form_lowSignalPla: "请输入最低信号值",
            form_SiganWeakNum: "信号弱次数",
            form_SiganWeakNumPla: "请输入信号弱次数",
            form_deviceMoelPlaNo: "请输入设备模型",

            ruler_modelName: "型号名称不能为空",
            ruler_modelLength: "长度1~20",
            ruler_modelCoding: "型号代码不能为空!",
            ruler_modelIntro: "型号简介不能为空!",
            ruler_devcieModel: "设备模型不能为空!",

            message_addModelSucc: "新增型号成功!",
            message_addModelLose: "新增型号失败!",
        },

        // 生产管理-型号指令
        modelCommand: {
            modelNum_orderName: "指令名称",
            modelNum_orderNamePla: "请输入指令名称",
            modelNum_modelNum: "所属型号",
            modelNum_editOrderTitle: "修改型号指令",
            modelNum_addOrderTitle: "新增型号指令",
            modelNum_orderArgument: "指令参数",
            modelNum_copy: "复制",
            modelNum_copySucc: "复制成功",
            modelNum_JsonText: "编辑模板参数",
            modelNum_modelPla: "请输入型号名称",
            modelNum_ruleOrder: "指令名称不能为空!",
            modelNum_ruleModelNum: "所属型号不能为空",
            modelNum_orderArgumentPla: "指令参数不能为空",
            type_drawerTitle: "设备类型详情",
            message_addOrderSucc: "新增指令成功!",
            message_addOrderLose: "新增指令失败!",
            message_editOrderSucc: "修改指令成功!",
            message_editOrderLose: "修改指令失败!",
        },

        // 生产管理-设备类型
        deviceType: {
            type_name: "类型名称",
            type_namePla: "请输入类型名称",
            type_coding: "类型编码",
            type_codingPla: "请输入类型编码",
            type_drawerTitle: "设备类型详情",
            type_dialogTitle: "新增设备类型",
            type_id: "类型ID",
        },

        // 生产管理-设备质检
        deviceCheck: {
            check_checkMid: "正在质检中",
            check_needCheck: "需要质检的设备",
            check_needCheckPla: "请输入设备imei...",
            check_lowSignal: "最低信号强度",
            check_lowVoltage: "最低电压(mv)",
            check_checkDayHearbeat: "是否检查24小时心跳",
            check_qualityTesting: "是否检查质检数据",
            check_alarmData: "是否检查报警数据",
            check_normalDevice: "正常设备",
            check_importClient: "导入客户",
            check_clearTableTips: "您确定要清空正常表格吗?",
            check_clearTable: "清空表格",
            check_abnormalDevice: "异常设备",
            check_abnormalNews: "异常信息",
            check_selectDevcieType: "请选择设备类型",
            check_deviceFormatLose: "设备格式不争取!",
            check_qualitySucc: "质检完成!",
            check_qualityLose: "质检失败!",
            messge_qualitySucc: "设备已经质检完成!",
            check_desc_uncheck: "未检查",
            check_desc_success: "正常",
            check_desc_error: "异常",
        },

        // 生产管理_质检记录
        checkRecord: {
            record_result: "全部质检结果",
            record_normal: "正常",
            record_abnormal: "异常",
            record_checkLog: "检查日志",
            record_checkTime: "质检时间",
            record_checkConditions: "质检详情",
            record_qualityNormal: "质检正常",
            record_qualityAbnormal: "质检异常"
        },

        // 生产管理_设备导入:{
        deviceImport: {
            import_plarform: "平台",
            import_plarformPla: "选择平台搜索",
            import_type: "导入类型",
            import_import: "导入",
            import_signOut: "注销",
            import_importEntity: "导入单位",
            import_singIngPlafrom: "注册平台",
            import_Time: "导入时间",
            import_Log: "导入日志",
            import_state: "导入情况",
            import_download: "下载附件",
            import_dialogTitle: "导入设备",
            import_singIngPlafromPla: "请选择注册平台",
            import_expireTime: "到期时间",
            import_selectTime: "选择日期",
            import_drawerTitle: "设备导入详情",
            import_deviceState: "设备导入情况",
            import_signInResult: "注册结果",
            import_successful: "成功",
            import_lose: "失败",
            import_NosignInLog: "注册日志",

            import_signInLog: "注册日志",
            import_signInTime: "注册时间",
            import_allType: "全部导入类型",
            import_deviceImport: "设备导入",
            import_deviceSignOut: "设备注销",
            import_importSucc: "导入成功!",
            import_importLose: "导入失败!",

            message_devcieModelNum: "设备型号不能为空!",
            message_signInPlarform: "注册平台不能为空!",
            message_importEntity: "导入单位不能为空!",
            message_devcieType: "设备类型不能为空!",
            message_importNo: "导入文件不能为空!",
            // message_
            import_file_type: "只能上传.xlsx文件"
        },

        //  生产管理-注册记录
        registerRecord: {
            register_platformPla: "请选择注册平台",
            register_allResult: "全部注册结果",
            register_registerSucc: "注册成功",
            register_registerLose: "注册失败",
        },

        // 生产管理-注销记录
        logOutRecord: {
            logOut_platform: "注销平台",
            logOut_result: "注销结果",
            logOut_resultLog: "注销日志",

            logOut_time: "注销时间"
        },

        // 预警管理-预警规则
        alarmRule: {
            alarm_radius: "范围级别",
            alarm_opentState: "开启状态",
            alarm_searPla: "请输入设备编码/单位名称搜索",
            alarm_deleteTips: "确定要删除此预警规则吗?",
            alarm_delete: "删除",
            alarm_drawerTitle: "新增预警规则",

            add_titleOne: "创建规则",
            add_titlePlaOne: "选择预警范围,设置预警对象",
            add_titleTwo: "添加接警人员",
            add_titlePlaTwo: "添加关联规则的系统用户",
            add_titleThreePla: "添加关联规则的临时用户",
            add_open: "开启",
            add_alarmObject: "预警对象",
            add_previous: "上一步",
            add_next: "下一步",
            add_deviceID: "设备ID",
            add_deviceName: "设备名称",
            add_deviceOther: "其他信息",
            add_devicePla: "请输入设备编码",
            add_eventTypeNoEmpty: "事件类型不能为空",
            add_selectAlarm: "请选择预警对象",
            add_createrAlarmSucc: "创建预警规则成功!",
            add_createrAlarmLose: "创建预警规则失败!",
            add_entityRank: "单位级别",
            add_deviceRank: "设备级别",

            sys_title: "系统用户列表",
            sys_searName: "姓名搜索",
            sys_phoneName: "手机号码搜索",
            sys_searBtn: "搜索",
            sys_clearSear: "清空条件",
            sys_name: "姓名",
            sys_phone: "联系电话",
            sys_role: "角色",
            sys_addTips: "您确定要将该用户添加到预警规则中吗?",
            sys_add: "添加",
            sys_cardTitle: "已关联规则的系统用户",
            sys_RemoveTips: "您确定要将该用户从预警规则中移除吗?",
            sys_removerBtn: "移除",
            sys_phoneFormat: "请输入正确的11位手机格式",
            sys_addSucc: "添加成功!",
            sys_addLose: "添加失败!",
            sys_romoverSucc: "移除成功!",
            sys_removerLose: "移除失败!",

            tem_title: "临时用户列表",
            tem_receivePhone: "是否接收电话",
            tem_receiveSms: "是否接收短信",
            tem_cardTitle: "已关联规则的临时用户",
        },

        // 预警规则-联动规则
        linkRules: {
            link_trigger: "触发设备",
            link_triggerPla: "请输入完整触发设备编码",
            link_linkage: "联动设备",
            link_editLinkageTips: "您确定要修改设备联动规则开启状态吗?",
            link_dialogTitel: "设备联动规则",

            message_openDetailsLose: "打开详情失败",
            message_delectSucc: "删除成功!",
            message_delectLose: "删除失败",

            add_triggerCondition: "触发条件",
            add_triggerConditionTips: "您确定要清空触发条件吗?",
            add_deviceCoding: "设备编码",
            add_eventType: "事件类型",
            add_isOpen: "是否开启",
            add_clearLinkageTips: "您确定要清空联动设备吗?",
            add_executeMOvement: "执行动作",
            add_executeMOvementTips: "您确定要清空执行动作吗?",
            add_devcieOrder: "设备指令",
            add_saveLink: "保存规则",
            add_setTrigger: "设置触发条件",
            add_setLinkage: "设置联动设备",
            add_triggerEvent: "触发事件",
            add_select: "选择",
            add_linakageCommand: "联动设备的命令",
            add_deviceModelNum: "设备型号",

            message_selectTrigger: "请选择触发的设备!",
            message_triggerEvent: "请选择触发的事件类型!",
            message_lingkaeDevice: "请选择联动的设备!",
            message_lingkaeCommand: "请选择联动执行的命令!",
            link_deleteTips: "确定要删除此联动规则吗?",
        },

        // 预警管理-临时用户
        temporarilyUser: {
            tem_receiveSms: "短信接收",
            tem_receivePhone: "电话接收",
            tem_name: "临时用户姓名",
            tem_UserPhone: "临时用户电话",
            tem_delectTips: "确定要删除此临时用户吗?",
            tem_drawerTitle: "临时用户详情",
            tem_dialogTitle: "新增临时用户",
            tem_rulerNoSpecialStr: "不能含有特殊字符!",
            tem_rulerPhoneMax: "电话必须是11位",
            tem_receive: "接收",
            tem_noReceive: "不接收",
            tem_phone: "电话",
            tem_isReceiveSms: "是否接收短信",
            tem_isReceivePhone: "是否接收电话",
            tem_creater: "创建",

            rule_length: "长度在 11 个字符",
            rule_UserNameNoSpecial: "临时用户姓名不能为空",
            rule_phoneNoSpecial: "电话不能为空",
            rule_receiveSms: "请选择是否接收短信",
            rule_receivePhone: "请选择是否接收电话",
            rule_createrUserID: "创建人ID",

            message_addSucc: "新增临时用户成功!",
            message_addLose: "新增临时用户失败!",
            // tem_
        },

        // 预警管理-型号模板
        modelNum: {
            model_notificationPla: "通知类型",
            model_templateName: "模板名称",
            model_templateNamePla: "请输入模板名称",
            model_deleteTips: "您确定要删除该通知模板吗?",
            model_relevanceModel: "关联型号",
            model_drawerTitle: "模板关联的型号",
            model_dialogTitleOne: "通知模板详情",
            model_dialogTItleAdd: "新增通知模板",
            model_templatArgument: "模板参数",
            model_save: "保存",
            model_NewCreater: "立即创建",
            model_SelectNotification: "请选择通知类型",
            model_selectEvent: "请选择事件类型",
            model_ruleTemplatArgument: "请填写模板参数",

            model_phone: "电话",
            model_note: "短信",
            model_PCPush: "PC推送",
            model_AppPush: "APP推送",
            model_OfficialAccounts: "公众号推送",

            message_saveTemplat: "请先保存模板参数",
            message_createrSucc: "创建成功!",
            message_createrLose: "创建失败!",

            relevance_title: "设备型号列表",
            relevance_modelName: "型号名称",
            relevance_modelNumIntro: "型号简介",
            relevance_addTips: "您确定要将该型号添加到通知模板中吗?",
            relevance_cardTitle: "已关联通知模板的型号",
            relevance_rmoeverTips: "您确定要将该型号从通知模板中移除吗?"
        },

        // 账户管理-单位管理
        entityManage: {

            entity_name: "单位名称",
            entity_namePla: "请输入单位名称",
            entity_addr: "单位地址",
            entity_addrPla: "请输入单位地址",
            entity_platformName: "平台名称",
            entity_platformNamePla: "请输入平台名称",
            entity_coding: "单位编号",
            entity_details: "详情",
            entity_addJunior: "新增下级",
            entity_drawerTitle: "单位详情",
            entity_entityNews: "单位信息",
            entity_confirmEdit: "确定修改",
            entity_logo: "单位logo",
            entity_addEntity: "添加单位",
            entity_platformLogo: "平台logo",
            entity_superiorsName: "上级单位",
            entity_oneImg: "只能上传一张logo!"
        },

        // 巡检巡查-巡检任务
        patrolTask: {
            task_naemSear: "巡检人姓名搜索",
            task_ssear: "搜索",
            task_chargePerson: "负责人",
            task_desc: "任务描述",
            task_type: "任务类型",
            task_state: "任务状态",
            task_notStarted: "未开始",
            task_underWay: "进行中",
            task_UnCompleted: "未完成",
            task_completed: "已完成",
            task_name: "巡检人姓名",
            task_phone: "巡检人电话",
            task_createrTime: "开始时间",
            task_deleteTips: "确定要删除此巡检任务吗?",
            task_deleteBtn: "删除",
            task_dialogTitle: "新增巡检任务",
            task_drawerTitle: "巡检任务详情",
            // task_

            add_name: "巡检人",
            add_roleName: "角色名称",
            add_phone: "手机号",
            add_UserNamePla: "请输入用户名搜索",
            add_starTime: "开始时间",
            add_endTime: "结束时间",
            add_taskLabel: "任务关联场所",
            add_selectTime: "选择日期时间",
            add_descPla: "请输入任务描述",
            add_submitFormBtn: "立即创建",
            add_rulesTaskType: "请选择任务类型",
            add_rulesPatrolMan: "请选择巡查人",
            add_rulesOpenTime: "请选择开启时间与结束时间",
            add_rulesEntity: "请选择所属单位",
            add_ruelsDesc: "请输入任务描述",
            add_addLose: "新增失败!",
            add_taskID: "任务ID",
            add_rulesSite: "请选择所场所",

            basic_hiddenTRoubleType: "隐患类型",
            basic_patrolName: "巡查人姓名",
            basic_patrolPhone: "巡检人电话",
            basic_patrolNameSex: "巡检人性别",
            basic_alterTime: "更改时间",
            basic_taskDesc: "任务描述",
            basic_cardTitle: "巡检记录",
            basic_createUser: "创建人",
            basic_patrolState: "巡查情况",

            basic_dialogTitle: "检查情况",
            basic_dangerLevel: "危险等级",
            basic_noDanger: "无危险",
            basic_oneDanger: "一级危险",
            basic_twoDanger: "二级危险",
            basic_threeDanger: "三级危险",
            basic_handleState: "处理状态",
            basic_processed: "已处理",
            basic_pending: "待处理",
            basic_abnormalTypeName: "异常类型名称",
            basic_checkImg: "检查图片",
            basic_AfterAbarbeitungImg: "整改后图片",
            basic_noAbarbeitungImg: "无整改图片",
            basic_uploadingServer: "确认上传到服务器",
            basic_abarbeitungImg: "整改图片",
            basic_messageOneImg: "请先选择上传的图片!",
            basic_messageOnlyUpImg: "每次只能上传一张图片!",

        },

        // 巡检巡查-巡检记录
        patrolRecord: {
            record_upLoadingServer: "上传服务器",
            record_dialogTitle: "新增巡检记录",
            record_site: "场所",
            record_belongTask: "请选择所属任务",
            record_inspectionResult: "巡检结果",
            record_inspectionResultPla: "请选择巡检结果",
            record_problemDesc: "问题描述",
            record_ruleSite: "请选关联场所",
            record_ruletask: "请选择任务",
            record_RuleInspectionResult: "请选择巡检结果",
            record_ruleRroblemDesc: "请输入问题描述"

        },

        // 巡检巡查-任务类型
        taskType: {
            type_deleteTips: "确定要删除此任务类型吗?",
            type_deleteBtn: "删除",
            type_taskTypeName: "任务类型名称",
            type_rules: "请输入异常类型名称",
            type_dialognTitle: "修改任务详情",
            type_addTitle: "新增任务类型",
            type_addLose: "新增失败!",

            type_language: '语言类型',
            type_languagePla: '请选择语言类型',
            type_languageRule: '语言类型不能为空',
        },

        // 巡检巡查
        patrolAll: {
            inspectImg: "检查图片",
            hiddenTroubleBtn: "修改",
            hiddenTroubleBtnTips: "确定要删除异常类型?",
            addHiddenTrouble: "新增异常类型",
            editTitle: "修改异常类型名称"
        },

        // 系统管理
        menuManage: {
            menu_title: "菜单标题",
            menu_name: "菜单名称",
            menu_path: "菜单路径",
            menu_grade: "菜单等级",
            menu_deleteTips: "您确定要删除菜单吗?",
            menu_details: "详情",
            menu_addMenu: "新增菜单",
            menu_menuDeatails: "菜单详情",
            menu_namePla: "请输入菜单名称",
            menu_pathPla: "请输入菜单路径",
            menu_titlePla: "请输入菜单标题",
            menu_icon: "菜单图标",
            menu_iconPla: "请输入菜单图标",
            menu_type: "菜单类型",
            menu_typePla: "请输入菜单类型",
            menu_module: "菜单组件",
            menu_superiorsMenu: "上级菜单",
            menu_ruleName: "菜单名称不能为空!",
            menu_rulePath: "菜单路径不能为空!",
            menu_ruleLengthMax: "长度在 1 到 50 个字符之间!",
            menu_ruleTitle: "菜单标题不能为空",
            menu_ruleIcon: "菜单图标不能为空!",
            menu_ruleType: "菜单类型不能为空!",
            menu_ruleTypeLength: "长度在 1 到 10 个字符",
            menu_ruleGrade: "菜单等级不能为空",

            messge_deleteSucc: "删除菜单成功!",
            messge_deleteLose: "删除菜单失败!",
            messge_addMenuSucc: "新增菜单成功!",
            messge_addMenuLose: "新增菜单失败!",
            messge_editSucc: "修改菜单成功!",
            messge_editLose: "修改菜单失败!",
        },

        // 系统管理-角色管理
        roleManage: {
            role_name: "角色名称",
            role_namePla: "请输入角色名称",
            role_deleteTips: "您确定要删除该角色吗?",
            role_addRole: "新增角色",
            role_editRole: "修改角色权限",
            role_message: "未修改权限",
            role_roelMenu: "菜单权限",
            role_ruleName: "请输入角色名称",
            role_messgeEditSucc: "修改成功!",
            role_messgeEditLose: "修改失败!",
            role_messageAddSucc: "新增成功!",
            role_messageAddLose: "新增失败!",

        },

        // 系统管理-导出记录
        exportRecords: {
            export_state: "数据状态",
            export_exportMidWay: "导出中",
            export_exportSucc: "导出成功",
            export_exportLose: "导出失败",
            export_downloadTips: "确定要下载此数据吗?",
            export_downloadBtn: "下载",
            export_deleteTips: "确定要删除此数据吗?",
            export_name: "数据名称",
            floorTitle: "楼层平面图",
        },

        // 导航栏
        menubar: {
            workbench: "工作台",
            moreHelp: "在线文档",
            backstageHomePaee: "后台首页",
            cancel: "取消",
            logOut: "您确定要退出登录吗?",
            backstageManage: "后台管理",
            SwitchLanguage: "切换语言",
            equipped: "有设备",
            AllEquipment: "全部设备"
        },

        // 子平台-智慧消防
        fireFightingPlatform: {
            fire_title: "智慧消防系统",
            fire_VideoTItle: '视频监控平台',
            fire_basicData: "基础数据",
            fire_alarmData: "预警数据",
            fire_account: "账号管理",
            fir_map: "预警地图",

            left_alarmRecords: "报警记录",
            left_faultRecords: "故障记录",
            left_deviceManage: "设备管理",
            left_siteUnit: "场所/建筑",
            left_floorList: "楼层列表",
            left_installPointManage: "安装点管理",
            left_patrolTask: "巡检任务",
            left_patrolRecords: "巡检记录",
            left_patrolState: "隐患情况",
            left_patrolType: "隐患类型",
            left_entityManage: "单位管理",
            left_userManage: "用户管理",
            left_deviceStatistics: "设备统计",
            left_alarmStatistics: "报警统计",
            left_faultStatistics: "故障统计",
            left_patrolTrouble: "隐患/巡查统计",
            left_devcieStateStatistics: "设备状态统计",
            left_troubleSatistics: "隐患统计",
            left_installStatistics: "安装统计",
            left_patrolStatistics: "巡检统计",

            map_deviceDetails: "设备详情",
            map_runData: "运行数据",
            map_orderTips: "确定开启此指令?",
            map_Controls: "操作",
            map_signal: "信号强度",
            map_voltage: "电压",
            map_temperature: "温度",
            map_concentration: "浓度值",
            map_installTime: "安装时间",
            map_noOrder: "该设备没有可以下发的指令",

            map_floorNum: "楼层数量",
            map_installPoint: "点位数量",
            map_go: "前往",


            Gas_title: "燃气安全系统",
            PRoduction_title: "生产管理系统",
            patrol_title: "巡检巡查管理系统",
            patrol_taskType: "任务类型",
            patrol_checkstate: "检查情况",
            patrol_type: "异常类型",
            map_deviceModelNum: "设备型号",
            Production_deviceModel: "设备模型",
            Production_modelNum: "型号指令",
            Production_PatrolRes: "质检记录",
            Production_devicePatrol: "设备质检",
            Production_deviceImport: "设备导入",
            Production_zuce: "注册记录",
            Production_zuxiao: "注销记录",


        },

        // 子平台-西乡燃气, 智慧消防
        xiXiangGasPlatform: {
            "xiXiang_PersonalMessage": "个人消息",
            "xiXiang_Subscribers": "用户",
            "xiXiang_Caption": "标题 ",
            "xiXiang_CreationTime": "创建时间",
            "xiXiang_ReadingSituation": "阅读情况",
            "xiXiang_Particulars": "详情",
            "xiXiang_Operation": "操作",
            "xiXiang_tipsReadMessage": "您确定已阅读此消息?",
            "xiXiang_tipsReadBtnTitle": "阅读",
            "xiXiang_tipsIsRead": "已阅读",
            "xiXiang_tipsUnReadBtnTitle": "未阅读",
            "xiXiang_Cancellations": "取消",
            "xiXiang_MakeCertain": "确定",

            "xiXiang_HomePage": "首页",
            "xiXiang_EquipmentManagement": "设备管理",
            "xiXiang_WorkOrderManagement": "工单管理",
            "xiXiang_OperationAndMaintenanceReport": "运维报告",
            "xiXiang_MerchantManagement": "商户管理",
            "xiXiang_systemManagement": "系统管理",
            "xiXiang_UserManagement": "用户管理",
            "xiXiang_MessageManagement": "消息管理",
            "xiXiang_LoginLog": "登录日志",
            "xiXiang_OperatingLog": "操作日志",

            "xiXiang_BigSmartScreen": "智慧大屏",
            "xiXiang_DecisionMakingCommandAtAGlance": "一图概览决策指挥",
            "xiXiang_RoutineInspection": "日常巡检",
            "xiXiang_ViewInspectionCompletionRates": "查看巡检完成率",
            "xiXiang_ViewO&MReports": "查看运维报告",
            "xiXiang_WorkOrderReporting": "工单上报",
            "xiXiang_ViewWorkOrderReporting": "查看工单上报",

            "xiXiang_PercentageOfEquipment": "设备占比",

            "xiXiang_EquipmentOverview": "设备概况",
            "xiXiang_TotalEquipment": "设备总数",
            "xiXiang_CallThePoliceToday": "今日报警",
            "xiXiang_TroubleshootingToday": "今日故障",
            "xiXiang_InstallationToday": "今日安装",
            "xiXiang_MaintenanceToday": "今日维保",

            "xiXiang_EquipmentOnlineRate": "设备在线率",
            "xiXiang_EquipmentOfflineRate": "设备离线率",
            "xiXiang_EquipmentFailureRate": "设备故障率",
            "xiXiang_EquipmentAlarmRate": "设备告警率",

            "xiXiang_AlarmOverview": "报警概况",
            "xiXiang_TotalNumberOfAlarms": "报警总数",
            "xiXiang_AlarmsProcessed": "已处理报警",
            "xiXiang_UnprocessedAlarms": "未处理报警",


            "xiXiang_Faultprofile": "故障概况",
            "xiXiang_TotalNumberOfFaults": "故障总数",
            "xiXiang_Troubleshooting": "已处理故障",
            "xiXiang_UntreatedFaults": "未处理故障",

        },

        //智慧消防地图 
        fireControl: {
            map_standardColor: "标准颜色",
            map_blue: "极夜蓝",
            map_cyan: "靛青蓝",
            map_gray: "雅士灰",
            map_black: "幻影黑",
            map_silver: "月光银",

            map_contacts: "联系人",
            map_contactsPhone: "联系人电话",
            map_NameOfPlace: "场所名称",
            map_latitude: "纬度",
            map_longitude: "经度",
            map_siteBasicTitle: "场所基本信息",
            map_deviceListTitle: "设备列表",
            map_deviceNum: "设备编码",
            map_deviceModelNum: "设备型号",
            map_mapStyle: "地图样式修改",
            map_goBacSitekNews: "返回场所信息",
            map_messageSite: "请先选择场所!",
            map_siteEntity: "单位",
            map_unit: "建筑",
            map_pushTitle: "推送记录",
            map_alarmHandle: "处理报警",

            map_state: "状态",

            map_select: "选择的是",

            map_placePoint: "放置点位",
            map_restPoint: "重置点位",
            map_confirmEditPoint: "确认修改点位",
            map_palceTIpes: "请点击平面图内,放置后请保存点位",
            map_firemAlarmSystem: "地图",

        },

        all: {
            deviceModel_typeName: "类型名称",
            deviceModel_typeNameRule: "类型名称不能为空",

            deviceCheck_tips: "确定要导入客户吗?",
            deviceCheck_normalDevcieSucc: "正常设备导入成功!",
            deviceCheck_normalDevcieLose: "正常设备导入失败!",
            deviceCheck_selectDevice: "请先选择正常设备的单位!",
            deviceCheck_abnormalDevcieSucc: "异常设备导入成功!",
            deviceCheck_abnormalDeviceLose: "异常设备导入失败!",
            deviceCheck_selectAbnormalDevcie: "请先选择正常设备的单位!",

            AlarmPlatform_deviceNo3D: "该设备未关联3D地图",
            AlarmPlatform_devcieNoModel: "该设备未关联3D模型",
            AlarmPlatform_alarmBanister_noCHeck: "接警栏空闲,无法查看",
            AlarmPlatform_noSpacing: "输入不能包含空格",
            AlarmPlatform_noDeviceType: '暂无设备类型',
            AlarmPLatform_noAlarmLog: '暂无报警日志',



            DutyLogManage_messageLogsNoSpacing: "日志内容不能全部为空格!",
            DutyLogManage_messageLog: "日志内容不能全部为空格!",
            DutyLogManage_messageAddSucc: "新增成功!",
            DutyLogManage_messageAddLose: "新增失败!",

            entity_enttiyName: "单位名称",
            entity_enttiyNameRule: "请输入单位名称",
            entity_platformLogName: "平台名称",
            entity_platformLogNameRule: "请输入平台名称",
            entity_platformLogNameRule_noSpace: "平台名称不能为空",
            entity_clcikMapPla: '点击查看设备,地图则显示该单位设备',
            entity_clearImp: '删除列表图片成功!',
            // DutyLogManage_
            // DutyLogManage_


            //用户管理
            userManage_loginNamePla: "11位电话号码或者字母与数字组成",
            userManage_passwordPla: "请输入密码",
            userManage_UserNnamePla: "请输入姓名",
            userManage_PhonePla: "请输入联系电话",
            userManage_genderPla: "请选择性别",
            userManage_rolePla: "请选择角色",
            userManage_entityPla: "请选择所属单位",
            userManage_registerPla: "请选择是否登录",
            userManage_imgUpData: '选择上传文件',
            userManage_imgTitle: '已上传的检查图片',
            userManage_superUser: '超级管理员',
            userManage_produceUser: '生产管理员',
            userManage_appletUser: '小程序管理员',
            userManage_unitUser: '单位管理员',
            userManage_addUserSucc: '新增用户成功',
            userManage_userExist: '该账号已存在',
            userManage_addUserLose: '新增用户失败',

            // 安装点管理
            pointManage_pointAddrRule: "点位地址不能为空",
            pointManage_pointNameRule: "点位名称不能为空",


            // 数字大屏幕
            Digital_Online_rate: "在线率",
            Digital_mapCheckDevcie: '查看设备',
            Digital_mapCheckFloor: '查看楼层',

            // 临时用户
            temporary_phoneChangPla: '确定要修改接收电话吗?',
            temporary_smsChangPla: '确定要修改接收短信吗?',

            // 绑定
            devcieBind_seleceMap: '请选择地图位置!',
            devcieBind_seleceMapLose: '请选择场所或新建场所!',

            // 报警记录
            alarmRecord_allAlarmState: '全部报警状态',

            // 联动规则
            linkRule_openState: '确定要修改此开启状态吗?',

            noData_threeDModel: '暂无3D模型',
            noDevcieData: '暂无设备数据',

            video_start: '开始',
            video_suspend: '暂停',
            video_multiple: '播放倍数',
            video_control: '视频控制',
            video_playVideo: '播放',
            video_refresh: '刷新',
            video_dataName: '文件名',
            video_messaeErr: '获取回放失败!请点击刷新按钮!',
            video_palyBalck: '视频回放',
            videe_levelShift: '水平移动速度',
            videe_verticalShift: '垂直移动速度',
            vide_videoManipulation: '监控操作',
            vide_regionalSetting: '区域设置',
            vide_clearCanvas: '清除画布',
            vide_saveCoordinate: '保存坐标',
            vide_morePoints: '最多可以设置6个点！',
            vide_leastPoints: '最少需要3个点！',
            vide_savePointsOk: '设置有效，准备保存',
            vide_savePointsError: '该区域无效，请重新设置',
            video_loding: '拼命加载视频列表...',

            videoSys_enven: '事件',
            videoSys_img: '图片',
            videoSys_online: '在线',
            videoSys_downline: '下线',
            videoSys_fault: '故障',
            videoSys_alarm: '报警',
            videoSys_heartbeat: '心跳',
            videoSys_flameAlarm: '火焰侦测报警',
            videoSys_smokeAlarm: '烟雾侦测报警',
            videoSys_flameSomkeAlarm: '火警侦测报警(火焰+烟雾)',
            videoSys_electrocarAlarm: '电瓶车识别报警',
            videoSys_unknownEvent: '未知事件',
            videoSys_heartbearEvent: '心跳事件',
            videoSys_faceRecognition: '人脸识别',

            videoSys_loading: '全力加载中....',
            videoSys_devcieDownLin: '此设备不在线',
            videoSys_allVideoList: '已加载全部数据',
            videoSys_videoControls: '摄像头操作',
            videoSys_title: ' 智慧安防云平台视频监控系统',
            videoSys_fullScreen: '全屏',
            videoSys_clearAll: '关闭所有',
            videoSys_passage: '通道',
            videoSys_loadingTwo: '拼命加载中...',
            videoSys_quitFullScren: '退出全屏',
            videoSys_playLose: '播放失败,请删除后重选设备',
            // videoSys_alarm:'',
            // videoSys_alarm:'',
            // videoSys_alarm:'',
            // videoSys_alarm:'',
            imgae_floor: '请先修改点位',

            devcieDetails_smokeArgument: '433烟感主机参数',
            devcieDetails_smokeHardware: '硬件版本',
            devcieDetails_smokeSoftWare: '软件版本',
            devcieDetails_smokeBu: '撤防/布防',
            devcieDetails_smokeSeparate: '正常/拆开',
            devcieDetails_smokeAudio: '声音/静音',
            devcieDetails_smokeErasureAudio: '未消音/消音',
            devcieDetails_smokeOpen: '阀门关闭/开启',
            devcieDetails_smokeMessErr: '获取烟感主机参数失败',
            devcieDetails_smokeBind: '已绑定',
            devcieDetails_smokeNoBind: '未绑定',
            devcieDetails_smokeIsBind: '是否绑定',
            devcieDetails_smokeChangerAddr: '修改地址与点位',
            devcieDetails_smokeAddr: '地址',
            devcieDetails_smokeAddrRule: '点位经纬度不能为空',
            devcieDetails_smokeAddrPla: '请输入地址',
            devcieDetails_smokePlachangePoint: '请先修改地图点位',
            devcieDetails_smokePointTip: '请拖动地图中的标记,来修改点位',
            devcieDetails_addSmokePointTip: '请点击地图来赋值点位',
            devcieDetails_smokeBian: '编辑',
            devcieDetails_smokePointChange: '请修改点位!',

            mqtt_deviceAlarm: '设备报警',
            mqtt_deviceAlarmDetail: '报警详情',
            mqtt_checkVideo: '查看视频',
            mqtt_model: '3D模型',

            siteNoEmpty: '场所不能为空!',
            time: '时间',

            site_businessHours: '营业时间',
            site_businessState: '营业状态',
            site_businessStatePla: '请选择运营状态',
            site_businessStateOpt1: '正常营业',
            site_businessStateOpt2: '停业整顿',
            site_businessStateOpt3: '存续',
            header_closeBlackAlarmDevice: "设备报警黑名单",
            header_alarmBell: '报警铃声',
            header_alarmRadio1: '报警地址播报',
            header_alarmRadio2: '警铃声播报',

            exportAll_pla: '您确定要导出当前条件下的所有数据吗?',
            exportAll_btn: '全部导出',


            user_name: '业主名称',
            user_namePla: '请输入业主名称',
            user_phone: '业主号码',
            installationPersonnel: "安装人员",
            installationPersonnel_input: "请输入安装人员名称",

            user_phonePla: '请输入业主电话',
            install_personnel: "安装人员",

            community_input: "请输入社区名称",
            community_name: "社区名称",

            install_time: '安装时间',

            site_nameLength: '长度不能大于50个字符',

            upData_imgPla: '请上传图片!',

            alarm_log_title: "当前尚无报警记录"
        },

        batchBind: {
            detail: "绑定结果",
            getDetailFailure: "查询绑定结果失败",
            addTitle: "批量绑定",
            entity: "绑定单位",
            message_importEntity: "绑定单位不能为空"
        },
        cameraList: {
            add: "新增",
        }
    }
}
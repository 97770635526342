<template>
    <div class="menuheaderbigbox">
        <div  :class="[layoutChange  ?  'menu-header' : 'menu-header-active']" >
            <div class="box-view-content">
                <div class="box-shadow">
                    <div class="menu-isShow" style="overflow: hidden;white-space:nowrap;width: 50%;">
                        <!-- <i class="iconfont icon-jianshaosuojin font_size" @click="changeCOllapse"></i>  -->
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="hidden-sm-and-down">
                            <transition-group name="breadcrumb" mode="out-in">
                                <template v-for="item in breadList" >
                                    <el-breadcrumb-item v-if="item.path!='/'" :key="item.title">
                                        <i class="iconfont" :class="item.icon"></i>
                                        {{item.title}}
                                    </el-breadcrumb-item>
                                </template>
                            </transition-group>
                        </el-breadcrumb>
                        <CommonTag ref="CommonTage" v-if="false"/>
                    </div>
                    <div class="menu-news">
                        <div class="personal_news">

                            <!-- 显示屏蔽设备报警黑名单弹出框 以及 展示屏蔽设备内容 的icon图标按钮 -->
                            <!-- <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'"  :content='$t("basic.all.header_closeBlackAlarmDevice")' placement="top" >
                                <div @click="getBlackDeviceDataList" style="display:flex;justify-content:center" id="B">
                                    <i class="iconfont icon-lixianjingbao" style="margin:0 6px;cursor:pointer; font-size: 24px;" ></i>
                                </div>
                            </el-tooltip> -->
                            
                            <div class="nowTime">{{ nowDate }}</div>

                            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'"  :content='$t("basic.all.header_alarmBell")' placement="top" >
                                <div @click="chagneAudio" style="display:flex;justify-content:center" id="B"><i class="iconfont icon-gaojing" style="margin:0 6px;cursor:pointer"></i></div>
                            </el-tooltip>


                            <!-- <el-dropdown placement="bottom" @command="changeLangeuage" >
                                <div style="display:flex;justify-content:center"><i class="iconfont icon-yuyan-02" style="margin:0 10px;cursor:pointer"></i></div>

                                <el-dropdown-menu slot="dropdown" style="width: 100px;margin: 10px 0;" >
                                    <el-dropdown-item command="zh" style="padding-left: 10px;">简体中文</el-dropdown-item>
                                    <el-dropdown-item command="en" style="padding-left: 10px;">English</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown> -->
                            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" :content='$t("basic.menubar.workbench")' placement="top" >
                                <div @click="changeRouter(1)" style="display:flex;justify-content:center" id="B"><i class="iconfont icon-gongzuotai" style="margin:0 6px;cursor:pointer"></i></div>
                            </el-tooltip>
                            <!-- <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" content='样式设置' placement="top" >
                                <div @click="changeRouter(4)" style="display:flex;justify-content:center"><i class="iconfont icon-yifu" style="margin:0 6px;cursor:pointer"></i></div>
                            </el-tooltip> -->
                            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" :content='$t("basic.menubar.moreHelp")' placement="top">
                                <div @click="changeRouter(3)"><i class="iconfont icon-wenhao-xianxingyuankuang" style="margin:0 6px;cursor:pointer"></i></div>
                            </el-tooltip>
                            <el-tooltip class="item" :effect="this.$CONFIG.THEME=='light'?'dark':'light'" :content='$t("basic.menubar.backstageHomePaee")' placement="top">
                                <div @click="changeRouter(2)"><i class="iconfont icon-shouye1" style="margin:0 6px;cursor:pointer;margin-right:15px"></i></div>
                            </el-tooltip>
                            
                            <div class="image fl_align_cen">
                               <i class="iconfont icon-touxiang" style="font-size: 25px;"></i>
                               <!-- <el-image :src="src" style="width:100%;height: 100%;border-radius: 50%;"></el-image> -->

                            </div>
                            <div class="name">
                                {{ userName }}
                            </div>
                            <el-popconfirm
                                :confirm-button-text='this.$t("basic.public.confirmNospace")'
                                :cancel-button-text='$t("basic.menubar.cancel")'
                                icon="el-icon-info"
                                icon-color="red"
                                :title='$t("basic.menubar.logOut")'
                                @confirm="logOout"
                            >
                                <el-button type="danger" icon="el-icon-switch-button" circle slot="reference"></el-button>
                            </el-popconfirm>
                        </div>
                        <div class="personal_news_hover">

                        </div>
                    </div>
                </div>    
            </div>
            </div>

            <!-- 显示已经屏蔽的用户 -->
            <!-- <el-dialog  
            :visible.sync="dialogTableVisible"
            :modal="true"
            :append-to-body="true"
            center
            >   
                <div class="closeDeviceAlarm">
                    <div style="margin-right:10px;">
                        <el-input  placeholder='请输入设备编号' v-model="deviceNumber" :clearable="true"></el-input>
                    </div>
                    <div class="clear">
                        <el-tooltip :content='$t("basic.public.clearSear")' placement="top">
                            <el-button class="clear" type="primary" style="margin-right: 15px;" size="medium" @click="clearSearchValue">
                                <i class="iconfont icon-shanchujinzhi"></i>
                            </el-button>
                        </el-tooltip>
                    </div>
                    <div class="search">
                        <el-tooltip :content='$t("basic.search.search")' placement="top">
                        <el-button  class="search" type="primary" style="margin-right: 15px; margin-left: 10px;" size="medium" @click="SerachBtn">
                                <i class="iconfont icon-sousuo" ></i>
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
                <el-table :data="deviceBlackData" style="width: 100%" max-height="420" :header-cell-style="{'text-align': 'center'}">
                    
                    <el-table-column
                    :property="item.prop" 
                    :label="$t(item.label)" 
                    width="150"
                    v-for="(item, index) in deviceBlackTableHeader"
                    :key="index"
                    :show-overflow-tooltip = "true"
                    align="center"
                    ></el-table-column>
                    
                    <el-table-column
                        label="操作"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-popconfirm
                            :title='$t("basic.public.sureUnblock")'
                            @confirm="deleteDeviceAlarm(scope.row)"
                            >
                            <el-button slot="reference" icon="el-icon-delete" type="danger" size="medium" style=" width: 100px; height: 35px;">{{$t("basic.public.Unblock")}}</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
                </el-table>
            </el-dialog> -->

            <!-- 选择报警铃声 -->
            <el-dialog
            :title="$t('basic.all.header_alarmBell')"
            :visible.sync="dialogVisible"
            width="400px"
            :destroy-on-close="true"
            :append-to-body="true"
           >
            <div style="width: 100%;height: 80px;">
                <el-radio-group v-model="newAduio" @input="changeAduio">
                    <div style="padding-bottom: 20px;">
                    <el-radio  label="addr"><span style="font-size: 20px;" >{{ $t('basic.all.header_alarmRadio1') }}</span></el-radio>
                </div>
                <div >
                    <el-radio  label="mp3"><span style="font-size: 20px;" >{{ $t('basic.all.header_alarmRadio2') }}</span></el-radio>
                </div>
            </el-radio-group>
           
            </div>
            
            </el-dialog>
        </div>
</template>
<script>
import CommonTag from './tabMenu.vue'
import { mapMutations } from 'vuex'

export default{
    name:'menuHeader',
    components:{
        CommonTag
    },
    data(){
        return{
            // routes:this.$router.options.routes,
            routes:[],
            isShowHeader:false,
            test:[],
            breadList: [
                {
                    title:this.$t("basic.menubar.backstageManage"),
                    icon:'iconfont icon-houtaiguanli',
                    name:'home',
                }
            ],
            driver:null,
            testShow:false,
            src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            userName:'',
            // userName:this.$store.state.
            ListMenu:'',
            dialogVisible:false,
            newAduio:null,
            dialogTableVisible: false,
            deviceBlackTableHeader: [
                {
                    label: "basic.public.deviceCoding",
                    prop: "device_number",
                },
                {
                    label: "basic.all.user_name",
                    prop: "contact_name",
                },
                {
                    label: "basic.public.MobilePhone",
                    prop: "contactr_phone",
                },
                {
                    label: "basic.public.siteName",
                    prop: "site_name",
                },
                {
                    label: "basic.public.create_date",
                    prop: "create_datetime",
                },
            ],
            // 屏蔽报警设备黑名单
            deviceBlackData: [],
            deviceNumber: null,
            nowDate: null,
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb();
        }
    },
    props:{
        layoutChange:{
           
        }
    },
    created() { 
        this.getTime();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000)
    },
     mounted(){
        setTimeout(()=>{
            this.newAduio = localStorage.getItem('aduio')
        },500)
        // console.log(this.newAduio,"1sadfafafasfasf",localStorage.getItem('aduio'));
        this.userName = JSON.parse(localStorage.getItem("AllTitle")).user_name
        this.getBreadcrumb();
        this.ListMenu =JSON.parse(localStorage.getItem("menu"))
        // this.routes = JSON.parse(localStorage.getItem("menu")) .slice(0,7)
        // if(JSON.parse(localStorage.getItem("menu")).length > 7)
        // {
        //      this.testShow = true
        //     this.test = this.this.$store.state.menu[ this.$store.state.menu -1].children.slice(7,8)
        // }
    },
    beforeDestroy() { 
        clearInterval(this.timer);
        this.timer = null;
    },
    methods:{
        ...mapMutations(['delUserInfo','delToken','delMenu']),
        // 清空黑名单弹出框条件查询内容
        clearSearchValue(){
            // 清空输入框
            this.deviceNumber = null;
            
            // 重新查询设备
            // this.getBlackDeviceDataList();

        },
        getTime() {
            let time = new Date().toLocaleString();
            this.nowDate = time.replaceAll("/", "-");
        },
        // 取消设备警告黑名单
        /*
        async deleteDeviceAlarm( item ){
            let data = null;
            let that = this;
            data = await that.$API.device.deletePreventDeviceAlarm.post({deviceId: item.id})
            await this.$message({
                message: "取消屏蔽设备成功",
                type: "success"
            })
            this.deviceBlackData = this.deviceBlackData.filter( val => item.id != val.id);
            return;
        },
        */
       
        // 搜索按钮 
        SerachBtn(){
            if( this.deviceNumber == null || this.deviceNumber.length == 0 || this.deviceNumber == "" ){
                this.$message({
                    message: "请输入设备编码",
                    type: "warning"
                })
                return;
            }
            this.deviceBlackData = this.deviceBlackData.filter( item => item.device_number == this.deviceNumber );
            console.log(this.deviceBlackData);
        },

        // 获取屏蔽设备信息的fn
        // 获取已经被屏蔽的报警列表
        /*
        async getBlackDeviceDataList(){
            let data = null;
            let that = this;
            data = await that.$API.device.getBlackAlarmDeviceList.get();
            this.dialogTableVisible = true
            if(data.code == 200){
                if(!data.data.count) return;  // 请求到的数据中没有被屏蔽的设备, 不执行以下的操作
                this.deviceBlackData = [...data.data.data];
                this.deviceBlackData.forEach(item => {
                    for (const key in item) {
                        if ( item[key] == "" || item[key] == null) {
                            item[key] = "--"; 
                        }
                    }
                })
                return;
            }
            this.$message({
                message: "没有被屏蔽报警的设备",
                type: "error"
            })
        },
       */

        changeCOllapse(){
            this.$store.dispatch('changeCollapse')
        },
        gotoHeaderChile(key,keyPath){
            if(keyPath.length == 2){
                this.$router.push(`/`+ keyPath[0] +`/` +keyPath[1])
            }else if(keyPath.length == 3){
                if(keyPath[0] == 550){
                    this.$router.push(`/` +keyPath[1]+`/`+keyPath[2])
                }else{
                    this.$router.push(`/`+ keyPath[0] +`/` +keyPath[1]+`/`+keyPath[2])
                }
            } else{
                this.$router.push(`/`+ keyPath[0] )
            }
        },
        //退出登录
        logOout(){
            if(this.$CONFIG.MQTT_CLINET !=null &&this.$CONFIG.MQTT_CLINET.connected){
                // console.log(this.mtopic,'=====',this.$TOOL.data.get('user').userInfo.userName);
                let topic = 'device/alarm/' + localStorage.getItem("loginName")
                 this.$CONFIG.MQTT_CLINET.unsubscribe(topic,err=>{
                    console.log("取消订阅成功",err);
                })
            }
            this.delUserInfo()
            this.delToken()
            this.delMenu()
            this.delCookie();//调用 
            this.$router.push({name:'login'})
        },
        delCookie() {
            document.cookie = "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
       async getBreadcrumb(){
            // let matched = this.$route.matched;
            this.ListMenu = JSON.parse(localStorage.getItem("menu"))
            this.breadList = [
                {
                    title:this.$t("basic.menubar.backstageManage"),
                    icon:'iconfont icon-houtaiguanli',
                    name:'home',
                }
            ],
            await  this.getMeta(this.ListMenu, this.$route.matched[1].path) 

            if(this.$route.matched.length > 2){
                await this.getMeta(this.ListMenu, this.$route.matched[2].path) 
            }
            
        },
        changeRouter(num){
            if(num == 1){
                this.$router.push({name:'ApplicationCenter'})
            }else if(num == 2){
                this.$router.push({name:'adminHome'})
            }else if(num ==3){
                const w = window.open("about:blank");
                w.location.href = 'https://www.yuque.com/yiguangjun/iu554o?#'
                
            }else if(num == 4){
                this.$store.commit('openDrwer')
            }
        },
          // 拼接服务器URL
        getURL(url){
            return this.$TOOL.getMediaUrl(url)
        },

        getMeta(list,title){
            list.forEach(item=>{
               if(item.path == title){
                    this.breadList.push(item.Meta)
                    return
               }else{
                if(item.children){
                    // 有子
                    this.getMeta(item.children,title)
                }
               }
            })
        },

        transfer(){
            // console.log(val,"tiao是什么");
            // this.$refs.CommonTage.tiao(val)
        },
         // 切换语言
         changeLangeuage(val){
            localStorage.setItem("theLanguage",val)
            this.$store.commit('changeLanguage',val)
            this.$router.go(0)
        },

        chagneAudio(){
            this.dialogVisible = true
        },
        changeAduio(val){
            localStorage.setItem('aduio',val)
        },
        


    }
}

</script>
<style lang="scss" scoped>
.closeDeviceAlarm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    .clear{
        width: 40px;
        height: 40px;
    }
    .search{
        width: 40px;
        height: 40px;
    }
}


.nowTime{
    font-size: 18px;
    font-weight: 500;
    font-style: oblique;
    color: #f56c6c;
    margin-right: 20px;
}

// @import "@/assets/scss/elemtntChange/menuLaout.scss";
@import "@/assets/scss/menu/menu_header.scss";
@import "@/assets/scss/flex/flex-all.scss"


</style>
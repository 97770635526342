import config from "@/config"
import http from "@/utils/request"

export default{
    list:{
        url:`${config.API_URL}/workOrder/`,
        name:'工单列表',
        get: async function(data){
            return await http.get(this.url,data)
        }
    },
    typeList:{
        url:`${config.API_URL}/workOrderType/`,
        name:'工单类型',
        get: async function(data){
            return await http.get(this.url,data)
        }
    },
    add:{
        url:`${config.API_URL}/workOrder/`,
        name:'创建工单',
        post: async function(data){
            return await http.post(this.url,data)
        }
    },
    setWorkOrderOwner:{
        url:`${config.API_URL}/setWorkOrderOwner/`,
        name:'指派工单',
        post: async function(id, data){
            return await http.post(this.url + id + "/", data);
        }
    },
    
    addImg:{
        url:`${config.API_URL}/workOrderImage/`,
        name:'新增图片',
        post: async function(url,data){
            return await http.post(this.url+url+'/',data)
        }
    },
    details:{
        url:`${config.API_URL}/workOrder/`,
        name:'详情',
        get: async function(id,data){
            return await http.get(this.url+id+'/',data)
        }
    },
    records:{
        url:`${config.API_URL}/workOrderHandle/`,
        name:'工单处理记录',
        get: async function(id,data){
            return await http.get(this.url+id+'/',data)
        }
    },
    clearRecords:{
        url:`${config.API_URL}/workOrderHandle/`,
        name:'新增处理记录',
        post: async function(data){
            return await http.post(this.url,data)
        }
    },
    clearImg:{
        url:`${config.API_URL}/workOrderHandleImage/`,
        name:'新增处理图片',
        post: async function(id,data){
            return await http.post(this.url+id+'/',data)
        }
    },
    // 设备地图点位
    devcieMap:{
        url:`${config.API_URL}/queryDeviceData`,
        name:'新增处理图片',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },

    coordinate:{
        url:`${config.API_URL}/xiXiang/xixiangGas/coordinate`,
        name:'查询设备点位',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },

    alarmNum:{
        url:`${config.API_URL}/xiXiang/home/allAlarmRecordCount`,
        name:'报警次数',
        get: async function(){
            return await http.get(this.url)
        }
    },
    todayAlarmRecordCount:{
        url:`${config.API_URL}/xiXiang/xixiangGas/todayAlarmRecordCount`,
        name:'查询今天报警数量',
        get: async function(){
            return await http.get(this.url)
        }
    },

    handleAlarm:{
        url:`${config.API_URL}/statistics/alarm/alarmProcessingNumber`,
        name:'已处理报警次数',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },
    alarmRecords:{
        url:`${config.API_URL}/statistics/alarm/reasonForAlarmProportion`,
        name:'报警原因占比',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },
    alarmType:{
        url:`${config.API_URL}/xiXiang/xixiangGas/TodayAlarmRecordTypeProportion`,
        name:'报警类型占比',
        get: async function(){
            return await http.get(this.url)
        }
    },
    allAlarmRecordTypeProportion:{
        url:`${config.API_URL}/xiXiang/home/allAlarmRecordTypeProportion`,
        name:'设备所有报警记录类型占比',
        get: async function(){
            return await http.get(this.url)
        }
    },

    falutNum:{
        url:`${config.API_URL}/xiXiang/home/allFaultRecordCount`,
        name:'故障次数',
        get: async function(){
            return await http.get(this.url)
        }
    },
    falutCount:{
        url:`${config.API_URL}/xiXiang/xixiangGas/faultCount`,
        name:'今日故障处理数量(已处理数量, 未处理数量)',
        get: async function(){
            return await http.get(this.url)
        }
    },
    handlefault:{
        url:`${config.API_URL}/statistics/fault/processedFaultNumber`,
        name:'已处理故障次数',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },
    faultRecords:{
        url:`${config.API_URL}/statistics/fault/faultProportion`,
        name:'故障原因占比',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },
    allFaultRecordTypeProportion:{
        url:`${config.API_URL}/xiXiang/home/allFaultRecordTypeProportion`,
        name:'设备所有故障记录原因占比',
        get: async function(){
            return await http.get(this.url)
        }
    },

    todayFaultTypeProportion:{
        url:`${config.API_URL}/xiXiang/xixiangGas/todayFaultTypeProportion`,
        name:'今日故障类型占比',
        get: async function(){
            return await http.get(this.url)
        }
    },

    notification:{
        url:`${config.API_URL}/notification/`,
        name:'通知消息列表',
        get: async function(data){
            return await http.get(this.url,data)
        }
    },
    newReadNotification:{
        url:`${config.API_URL}/notification/`,
        name:'消息详情/查看消息',
        get: async function(id){
            return await http.get(this.url+'/'+id+'/',)
        }
    },

    personNotification:{
        url:`${config.API_URL}/personNotification`,
        name:'个人消息通知列表',
        get: async function(data){
            return await http.get(this.url+'/',data)
        }
    },

    readNotification:{
        url:`${config.API_URL}/notificationRead`,
        name:'个人消息通知列表',
        get: async function(id,data){
            return await http.get(this.url+'/'+id+'/',data)
        }
    },

    
	addnotification:{
		url: `${config.API_URL}/notification/`,
		name: "新增通知消息",
		post: async function(data){
			return await http.post(this.url,data);
		}
	},

    statisticsList:{
		url: `${config.API_URL}/statisticsReport/statisticsReport`,
		name: "单位用户所有的运维报告",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
    reportList:{
		url: `${config.API_URL}/xiXiang/report/list`,
		name: "查询运维报告列表",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},


    addNewSiteNumber:{
		url: `${config.API_URL}/statisticsReport/addNewSiteNumber`,
		name: "查询单位新增的场所数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    workOrderType:{
		url: `${config.API_URL}/statisticsReport/workOrderType`,
		name: "查询工单类型数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    workOrderStatus:{
		url: `${config.API_URL}/statisticsReport/workOrderStatus`,
		name: "查询工单状态数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    faultProcessingStatus:{
		url: `${config.API_URL}/statisticsReport/faultProcessingStatus`,
		name: "查询故障处理数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    statisticsReport:{
		url: `${config.API_URL}/xiXiang/report/statisticsReport`,
		name: "查询运维报告详情",
		get: async function(data){
			return await http.get(this.url, data);
		}
	},

    faultType:{
		url: `${config.API_URL}/statisticsReport/faultType`,
		name: "查询故障原因数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    alarmCheck:{
		url: `${config.API_URL}/statisticsReport/alarmCheck`,
		name: "查询设备报警类型数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    deviceType:{
		url: `${config.API_URL}/statisticsReport/deviceType`,
		name: "查询设备类型数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
    
    StatisAlarmType:{
		url: `${config.API_URL}/statisticsReport/alarmType`,
		name: "查询设备报警原因数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},

    deviceStatus:{
		url: `${config.API_URL}/statisticsReport/deviceStatus`,
		name: "查询设备状态数量",
		get: async function(data){
			return await http.get(this.url,data);
		}
	},
    

    offLIne:{
        url: `${config.API_URL}/statisticsReport/offlineTrend`,
		name: "离线设备趋势",
		get: async function(data){
			return await http.get(this.url,data);
		}
    },
    oneLine:{
        url: `${config.API_URL}/statisticsReport/onlineTrend`,
		name: "在线设备趋势",
		get: async function(data){
			return await http.get(this.url,data);
		}
    },

    loginLogs:{
        url: `${config.API_URL}/log/login`,
		name: "登录日志",
		get: async function(data){
			return await http.get(this.url,data);
		}
    },
    operationLogs:{
        url: `${config.API_URL}/log/operation`,
		name: "操作日志",
		get: async function(data){
			return await http.get(this.url,data);
		}
    },

    issuedTicketReminders:{
        url: `${config.API_URL}/xiXiang/workOrder/issuedTicketReminders`,
		name: "下发工单催办",
		post: async function(data){
			return await http.post(this.url, data);
		}
    },
    getSiteDeviceOnLineRate: {
        url: `${config.API_URL}/xiXiang/home/querySiteDeviceOnlineRate`,
        name: "查询场所设备在线率",
        get: async function () {
            return await http.get(this.url);
        }
    }
}
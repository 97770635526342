export default {
    basic: {

        // 公用
        public: {
            workbench: 'workbench',
            clearSear: 'Clear query condition',
            refresh: 'Refresh Table',
            search: 'Search',
            // 单位
            entitySearch: 'Enter unit name',
            entityID: 'Unit ID',
            entityName: "Unit name",
            entityAddr: 'Unit address',
            // 下拉tble框中的场所
            siteID: 'Site ID',
            siteName: 'Site name',
            other: 'Site address',
            siteSearchPla: 'Enter site name',
            // 下拉tble框中的类型
            typeSearch: 'Enter type name',
            typeID: 'Type ID',
            typeName: 'Type name',
            typeOther: 'Type code',
            typeTitle: 'type',
            // 型号
            modelSearch: 'Enter Model Name',
            modelID: 'Model ID',
            modelName: 'Model  name',
            modelOther: 'Model  code',
            // 模型
            Two_modelSearch: 'Enter model name',
            Two_modelID: 'Model ID',
            Two_modelName: 'Model name',
            Two_modelOther: 'Creation time',

            // 屏蔽
            Unblock: "Unblock",
            sureUnblock: "Are you sure you want to cancel the blocking warning?",

            // 操作
            details: 'Details',
            controls: 'Operation',
            OK: 'confirm',
            confirmBtn: 'confirm',
            cancel: 'Cancel',
            confirmNospace: 'OK',
            cancelNospace: 'Cancel',
            controls_collect: 'Focus',
            cancelOperation: 'Cancel',
            addBtn: 'Add',
            delete: 'Delete',

            //  规则
            rulesSpace: 'Disable space',
            rule_entityNospace: 'Unit ID cannot be empty',
            basicInformation: 'Information',

            open: 'Open',
            close: 'Close',
            closeSpace: 'close',
            Time_midden: 'To',
            Time_start: 'Start date',
            Time_end: 'End date',
            create_date: "Date of creation",
            MobilePhone: 'Mobile phone',
            sendingTime: 'Sending time',
            eventType: 'Event type',
            deviceCoding: 'code',
            installAddr: 'Location',
            alarmReason: 'Reason',
            alarmTiem: 'Alarm time',
            searchDeviceCoding: 'Enter full device code',
            moreSear: 'More search',
            owner: "owner",
            owner_phone: "owner phone",
            alarm_type: "alarm type",

            notAllowBatch: 'Bulk deletion not allowed!',

            //messageAll
            message_addSucce: 'Add  succeed!',
            message_addLose: 'Add  fail!',
            messageAllClear: 'All Filters Cleared!',
            messageAllRefresh: 'Refresh successfully!',
            messageAllSear: 'Search Succeede!',
            message_collectSucc: 'Focused successfully',
            message_collectLose: 'Failed to focus',
            message_importSucc: 'Data export success!',
            message_importLose: 'Data export failure!',
            message_importRule: 'Select the data you want to export',
            message_deleteImgSucc: 'Image deleted successfully!',
            message_deleteImgLose: 'Image deletion failed!',
            message_ImgUpSucc: 'Data upload success!',
            message_ImgUpLose: 'Data upload failure!',
            message_editSucc: 'Modified successfully!',
            message_editLose: 'Modification failure',
            message_addDataSucc: 'Add  succeed!',
            message_addDataLose: 'Failed to add data!',
            message_deleteSucc: 'Deleting data succeeded!',
            message_deleteLose: 'Failed to delete data!',
            message_exceedMaxImg: 'Upload failed! Only one photo can be uploaded!',
            // message_
            // message_
            // message_

            // 文件
            file_btnAdd: 'Upload',
            file_attachmentAdd: 'Attachment upload',
            file_isUpIMg: 'Confirm uploading this image?',
            file_Tips: 'Tips',



            // 表格
            derive: 'Export',
            deriveTips: 'Confirm Exporting Data?',
            table_createdTime: 'Creation time',
            table_entity: 'Unit',
            table_deviceType: 'Type',
            table_OwnersName: "Owner's name",
            table_OwnerTelephone: "Owner telephone",
            table_installationPersonnel: "installation personnel",

            table_community: "community",

            table_deviceModel: 'Model',
            table_deviceState: 'State',
            table_deviceLatelyTime: 'Recent activity time',
            table_useType: "type of use",
            table_handleMan: 'Handler',
            table_handleTime: 'Handling time',
            table_handleSuggestion: 'Remark',
            table_FaultCause: 'Reason',
            table_faultTime: 'Time',
            table_informant: 'Reporter',
            table_faultDesc: 'Description',
            table_alarmRulesRadius: 'Alarm range',
            table_alarmRulesEvent: 'Alarm event',
            table_alarmRulesIsOpen: 'Enable or not',
            table_alarmRulesDevice: 'Alarm device',
            table_alarmRulesEntity: 'Alarm unit',
            table_UpTime: 'Update time',
            table_pointName: 'Point name',
            table_pointAddr: 'Point address',
            table_relevanceDevice: 'Device',
            table_entityName: 'Unit name',
            table_region: 'Affiliated area',
            // table_

            // select的label
            select_all: 'All',
            select_Install: 'Installed',
            select_uninstalled: 'Uninstalled',
            // select_
            // select_
            // select_
            rule_content: 'Please Enter content',
            rule_data: 'Please select data',

            gas_vase: "gas",
            natural_gas: "natural gas",
        },
        // 搜索的内容
        search: {
            sitePla: 'Enter site name',
            typePla: 'Enter type name',
            deviceCodePla: 'Enter full device code',
            ownerPla: "Enter owner",
            ownerPhonePla: "Enter owner phone",
            search: 'Search',
            searchNoMore: "No more.",
            isLoading: "Loading",
        },
        // 省市县组件
        region: {
            city: 'Select province',
            county: 'Select province or city',
            provincePla: 'Select province',
            cityPla: 'Please select city',
            countyPla: 'Please select the county'
        },

        // 应用中心页面
        ApplicationCenterPage: {
            title: 'Application centre',
            logOut: 'Logout',
            backStage: 'Admin Dashboard',
            NumScreen: 'Data Visualization',
            alarmPlatform: 'Alert Management',
            patrol: 'Inspection',
            production: 'Manufacturing',
            childPlatform: 'Subplatform',
            logOutTips: 'Sure to log out?',
            chilListFiren: 'Fire Management Platform',
            chilListGas: 'XiXIANG Gas platform',
            chilListGasB: 'Gas Management Platform',
            yes: 'OK',
            cancel: 'Cancel'
        },
        // 登录页面
        newLogin: {
            title: 'INTELLIGENT SECURITY CLOUD PLATFORM',
            login: 'Log in',
            userPla: 'Account',
            userRules: 'Please enter your account',
            passwordPla: 'Password',
            passwordRules: 'Please enter your password',
            passwordRulesLength: 'Minimum length must be 6 characters',
            platformTitle: 'Security back-stage management',
            loginSucc: 'Login Successful!',
            loginLose: 'Account does not exist or password is incorrect',
        },
        // Digital display
        digitalPage: {
            title: "Admin Dashboard",

            deviceTitle: 'Device statistics',
            deviceAllNum: 'Total ',
            deviceOnLine: 'Online',
            deviceOffLIne: 'Offline ',
            deviceInstall: 'Installed ',
            deviceNoInstall: "uninstalled",
            leftMiddenTilt: 'installation progress',
            leftMiddenNum: 'Number',
            leftMiddenpercent: 'Percentage',

            leftBottomTitle: 'Device type statistics',
            leftBottomDevice: 'Devices',

            middenTop_todayAlarm: 'Alarms',
            middenTop_fault: 'Faults',
            middenTop_install: 'Installations',
            middenTop_maintenance: 'Maintenance',

            middenMap_owenr: 'Owner information',
            middenMap_owenrPhone: 'Owner phone',
            middenMap_addr: 'Site address',
            middenMap_siteName: 'Site name',
            middenMap_siteDeviceNum: 'Devices',
            middenMap_floorNum: 'Floors',
            middenMap_dilogTitle: 'Point list',

            middenMap_dialog_owenrName: 'Owner name',
            middenMap_dialog_owenrPhone: 'Owner phone',
            middenMap_dialog_siteName: 'Site name',
            middenMap_dialog_siteAddr: 'Site address',

            middenBottom_entity: 'Unit',
            middenBottom_jianzhu: 'Building',
            middenBottom_device: 'Devices',
            middenBottom_user: 'User',
            middenBottom_sys: 'Setting',

            rightTop_alarm: 'Alarm',
            rightTop_fault: 'Malfunction',

            rightMidden_title: 'Device state statistics',
            rightMidden_offline: 'Offline',
            rightMidden_tamperd: 'Removal',
            rightMidden_highTemperature: 'High temperature',
            rightMidden_low_signal: 'Weak signal',
            rightMidden_lowBattery: 'Low voltage',
            rightMidden_fault: 'Sensor failure',
            rightMidden_alarm: 'Alarm',
            feifa_disassemble: 'Illegal demolition',
            rengong_fault: 'Manual support',
            rightBottom_alarmAddr: 'Alarm address',
            rightBottom_deviceNum: 'code',
            rightBottom_alarmType: 'Alarm type',

            dialog_entityTitle: 'Entity',
            dialog_entitySear: 'Please select the unit name',
            dialog_entityName: 'Unit',
            dialog_entityAddr: 'Unit address',
            dialog_entityPlatformName: "Platform name",
            dialog_entityGo: 'Find Device',

            dialog_siteTitle: 'Building list',
            dialog_siteSearName: 'Enter site name',
            dialog_siteAddrSear: 'Enter Site Address',
            dialog_siteAddr: 'Site address',
            dialog_siteName: 'Site name',
            dialog_siteEntityName: 'Unit',
            dialog_siteRegion: 'Affiliated area',

            dialog_deviceNum: 'Enter full device code',
            dialog_deviceState: 'Select device state',
            dialog_deviceOrder: 'Command',
            dialog_deviceNumTable: 'Code',
            dialog_deviceEntity: 'Unit',
            dialog_deviceType: 'Type',
            dialog_deviceInstallAddr: 'install site',
            dialog_deviceTypetwo: 'Model',
            dialog_deviceStateTable: 'Device state',
            dialog_deviceEventsTime: 'Recent activity time',

            dialog_userTitle: 'User list',
            dialog_userLoginName: 'Please enter your account',
            dialog_userNameSear: 'Please enter your name',
            dialog_userPhoneSear: 'Please enter your phone number',
            dialog_userEntityTitle: 'Unit',
            dialog_userEntityName: 'Unit',
            dialog_userPhone: 'Phone',
            dialog_userSex: 'Gender',
            dialog_userMan: 'Male',
            dialog_userWoMan: 'Female',
            dialog_userloginTable: 'Account',
            dialog_userJurisdiction: 'Role',
            dialog_userName: 'User name',
            dialog_userDeatil: 'View User Details',
            dialog_userDeatilBasice: 'Information',
            dialog_userDeatilBtn: 'Confirm modification',


            dialog_SettingTitle: 'Setting',
            dialog_SettingBackdrop: 'Background',
            dialog_SettingNormal: 'Standard color',
            dialog_SettingDarkblue: 'Polar Night Blue',
            dialog_Settingblue: 'Indigo Blue',
            dialog_SettingGrey: 'Eminent Gray',
            dialog_SettingDark: 'Phantom Black',
            dialog_SettingLight: 'Moonlight Silver',

            dialog_deviceListTitle: 'Device list',

        },

        // 设备列表
        deviceList: {
            search_isInstall: 'Install or not',

            state_title: 'Device state statistics',
            state_one: 'Device state',
            state_offline: 'Offline',
            state_tamperd: 'Removal',
            state_highTemperature: 'High temperature',
            state_low_signal: 'Weak signal',
            state_lowBattery: 'Low power',
            state_fault: 'Sensor failure',
            state_alarm: 'Alarm',
            state_normal: 'Normal',
            state_batteryState: 'Battery state',
            state_valveState: 'Valve state',



            device_detailTitle: 'Device details',
            device_detailBasice: 'Information',
            device_detailInstall: 'install',
            device_detailRun: 'Operation data',
            device_detailAlarm: 'Alarm handling',
            device_detailFault: 'Fault handling',
            device_detailVideo: 'Video',
            device_detailAlarmRule: 'Alarm rules',
            device_detailLInkage: 'Linkage rules',

            device_bind: 'bind',
            device_bindTitle: 'Binding device',
            deviceBind_Pla: 'Choose existing site or skip this input, create a new site below',
            // 屏蔽
            device_blockDevice: "cloture",
            device_recogniseBlockDevice: "Are you sure you want to block the device alarm?",
            device_SuccessfullyBlockedTheDevice: "Successfully blocked the device!",

            // 指令
            device_order: 'Command',
            device_orderTilte: 'Command issuance',
            device_orderTips: 'Confirm sending command?',
            device_orderNoList: 'No device instructions',
            device_noRunOrder: 'No device instructions',
            device_noOrder: 'No instructions for the device',
            devcie_orderSucc: 'Command delivery success!',
            device_orderLose: 'Instruction delivery failed!',
            // 更多操作
            device_controlsTitle: 'More operations',
            device_controlsInitialize: 'Initialize Device',
            device_controlsTransfer: 'Transfer Device',
            device_controlsRelieveBind: 'Unbind',
            device_controlsClearFault: 'Clear fault',
            device_controlsFang: 'Arming',
            device_controlsCefang: 'Disarm',
            device_controlsLock: 'Locking',
            device_controlsRelieveLock: 'Release lock',
            device_controlsInitializeCont: 'Clear Installation Data for the Device',
            device_controlsTransferCont: 'Transfer the device to other units',
            device_controlsRelieveBindCont: 'Unbind the current account from the device',
            device_controlsClearFaultCont: 'Clear all faults of the device',
            device_controlsFangCont: 'Arming devices, supporting all devices',
            device_controlsCefangCont: 'Disarming devices, supporting all devices',
            device_controlsLockCont: 'Locking',
            device_controlsRelieveLockCont: 'Release lock',
            device_DispatchWorkOrders: "Dispatch work orders",

            device_messgae: 'Select device to operate!',
            device_controlsInitializeTips: 'This action will remove device associations and restore unbound state. Continue?',
            device_controlsInitializeSucc: "Initialization successful!",
            device_controlsInitializeLose: 'Initialization failed!',

            device_controlsRelieveBindTips: 'This action will unbind the current account from the device. Continue?',
            device_controlsRelieveBindSucc: 'Device detach successful!!',
            device_controlsRelieveBindLose: 'Device untying failed!',

            device_controlsClearFaultTips: 'This action will clear all device faults. Continue?',
            device_controlsClearFaultSucc: 'All device faults cleared successfully!',
            device_controlsClearFaultLose: 'Failed to clear all device faults!',

            device_succ: 'Success!',
            device_lose: 'Fail!',
            device_cancel: 'Cancelled',
            device_warn: 'alarm',

            device_controlsLockTips: 'After locking the device, you will not be able to process the fault data of the device, only the alarm and heartbeat data! Are you sure you want to lock your device?',
            device_controlsLockDevice: 'Locking device',
            device_controlsRelieveLockTDevice: 'Unlock lock',
            device_controlsRelieveLockTips: 'Unlock device, restore normal functions. Confirm to unlock?',

            device_controlsTransferContSucc: 'Device transfer successful!',
            device_controlsTransferContLose: 'Device transfer failed!',

            device_collectTips: "Focus this device?",

            device_noInstall: 'Device not installed!',
            device_noCheckDeatil: 'Unable to view device details!',
            device_checkLose: 'Failed to query device details!',
            device_Novideo: 'Failed to get the play address!',

            // drawer-侧边弹出框
            drawer_title: 'Device details',
            drawer_installNews: 'install',
            drawer_runData: 'Operation data',
            drawer_alarmHandle: 'Alarm handling',
            drawer_faultHandle: 'Fault handling',
            drawer_video: 'Video',
            drawer_alarmRule: 'Alarm rules',
            drawer_linkageRule: 'Linkage rules',
            drawer_pushCondition: 'Push situation',
            drawer_BindUser: 'Binding personnel',
            drawer_eventImage: "Event Photos",

            // dialog-中心弹出框
            dialog_title: 'Device transfer',

            // Basic information
            basice_Title: 'Device information',
            basice_TwoCode: 'QR Code',
            basice_installTime: 'install time',
            basice_deviceType: 'Device type',
            basice_model: 'Model',
            basice_edit: 'Modify',
            basice_ccid: 'ccid',
            basice_telecomID: 'Telecom ID',
            basice_moveID: 'Mobile ID',
            basice_imei: 'imei',
            basice_imsi: 'imsi',
            basice_ToggleChart: 'Toggle icon',
            basice_toggleCar: 'Switch card',
            basice_deviceStats: 'Device property',
            basice_signalStrength: 'Signal',
            basice_voltage: 'Voltage',
            basice_temperatureNum: 'Temperature',
            basice_concentrationNum: 'Concentration',
            basice_deviceIMg: 'Device picture',
            basice_upImg: 'Upload picture',
            basice_deleteImgTips: 'Confirm to delete device images?',
            basice_chartTitle: 'Device attribute trend',
            basice_temperature: 'Temperature',
            basice_concentration: 'concentration',
            basice_chartDeviceNoData: 'The device does not report data, cannot display the property curve!',
            basice_copySucc: 'Copy successfully!',
            basice_copyLose: 'Sorry, copy failed!',
            basice_editSucc: 'Modified successfully!',
            basice_editLose: 'Modification failure!',
            basice_humidness: 'humidness',
            basice_HumidityValue: 'Humidity value',
            basice_brightScreen: 'Brighten the screen',
            basice_noScreen: 'Screen off',
            basice_TemperatureUnit: 'units',
            basice_DegreeCelsius: 'Degree Celsius',
            basice_Fahrenheit: 'Fahrenheit',
            basice_ScreenState: 'Screen state',
            basice_code: 'Code ',

            basice_alarm: 'Alarm',
            basice_cameraArgu: 'Camera parameter',
            basice_addr: 'Address',
            basice_state: 'State',
            basice_line: 'Online',
            basice_transmit: 'Stream transmission mode',
            basice_NewHeartbeat: 'Recent heartbeat',
            basice_NewSignIn: 'Recent registration',
            basice_passage: 'Number of channels',
            basice_bing: 'Device binding',
            basice_editMqtt: 'Modifying mqtt',
            basice_editHttp: 'Modifying http',
            basice_smoke: '433 Smoke detector',
            basice_relieveBind: 'unbind',
            basice_relieve: 'unbind',
            basice_setFang: 'Set up defensive zone',
            basice_fang: 'defence area',
            basice_confirmEdit: 'Confirm the modification?',
            basice_ipTips: 'Enter IP address/domain',
            basice_userNameTIps: 'Please enter your username',
            basice_password: 'Please enter password',
            basice_bindNameTips: 'Please enter a binding name',
            basice_fangqu: 'Please enter the area name',
            basice_messageCarmes: 'Failed to obtain camera details. Procedure!',
            basice_messageDeviceNodata: 'The device does not report data and cannot display attribute data',
            basice_messageAddr: 'The address cannot be empty!',
            basice_messageIssueSucc: 'Delivery success!',
            basice_messageIssueLose: 'Delivery failed!',
            basice_messageNOfang: 'Site name cannot be empty',
            basice_messageIssueSetSucc: 'Successfully sent area settings!',
            basice_messageIssueSetLose: 'Failed to send area settings!',
            basice_messageIssueBind: 'Failed to send the binding command!',
            basice_messageCarmesNoSmoke: 'Camera not connected to smoke detector!',
            basice_messageInquireLose: 'Query failure',
            basice_messageInquire433Lose: 'No smoke sensation!',
            basice_messageIssueORderLose: 'Failed to send the command!',
            basice_messageNoBindLose: 'Failed to send unbind command!',

            basice_deviceVoltage: 'Devices voltage',
            basice_temperatureNew: 'Field temperature',
            basice_formHUmidness: 'Relative humidity',
            basice_MoverNum: 'Number of moves',
            basice_signalQuality: 'Signal quality',
            basice_deviceIMSi: 'Device IMSI',

            // 安装信息
            install_title: 'Site information',
            install_checkSiteImg: 'Site pictures',
            install_owner: 'Owner',
            install_ownerPhone: 'Owners phone ',
            install_pointName: 'point name',
            install_pointCont: 'Site pictures',
            install_pointAddr: 'Point address',
            install_siteImg: 'Picture of site',
            install_mapAddr: 'Map',
            install_copyLatlng: 'Copy the latitude and longitude of site',
            install_getSiteNewsLose: 'Failed to retrieve site information',
            install_getPointNewsLose: 'Failed to retrieve site information',

            // 运行数据
            operation_alarmTitle: 'Alarm',
            operation_voltage: 'Voltage(mv)',
            operation_dataSource: 'Data source',
            operation_otherData: 'Other',
            operation_firmCoding: 'Manufacturer',
            operation_pushModel: 'Push model ',
            operation_pushType: 'Push type',
            operation_pagination: 'Current page',
            operation_faultTitle: 'Fault',
            operation_hearbeat: 'Heartbeat',
            operation_releaseRecord: 'Command record',
            operation_previous: 'Previous page',
            operation_NextPage: 'Next page',
            operation_resultCoding: 'Issue result code',
            operation_resultDesc: 'Issue result description',

            // 报警处理
            alarm_handle: 'handle',
            alarm_succ: 'Successful processing!',
            alarm_dialogTitle: 'Handle',
            alarm_handlePla: 'Select alarm reason',
            alarm_systematicPro: 'System generation',
            alarm_faultHandle: 'troubleshoot',
            alarm_isFinishMonad: 'Is it completed?',
            alarm_yes: 'Yes',
            alarm_no: 'No',
            alarm_feedback: 'Feedback',
            alarm_feedbackPla: 'Please enter feedback',
            alarm_impor: 'Import files',
            alarm_draweTitle: 'Details',
            alarm_faultType: 'Fault type',
            alarm_handleRecords: 'Processing record',
            alarm_handleImg: 'Process pictures',
            alarm_ImgCheck: 'Picture details',
            alarm_MessageChcekLose: 'View failed!',

            // bind
            bind_entity: 'Unit',
            bind_installSite: 'Select site',
            bind_siteName: 'Site name',
            bind_siteNameLian: "Site",
            bind_installPoint: 'Point name',
            bind_installPointPla: 'Enter point name',
            bind_isntallAddr: 'Point address',
            bind_installAddrPla: 'Enter point address',
            bind_subBind: 'Confirm',
            bind_selectRegion: 'Select Area',
            bind_addSite: 'Add Site',
            bind_siteNamePla: 'Enter site name',
            bind_siteAddr: 'Enter site address',
            bind_owner: 'Enter owner name',
            bind_ownerPhone: 'Enter owner contact number',
            bind_floorNum: 'floor',
            bind_dialogTitle: 'Set the site map site',
            bind_ruleSite: 'Select  site',
            bind_ruleSiteLength: 'Data length is 5 to 50 characters',
            bind_pointName: 'Please enter in the point name',
            bind_pointLength: '1-20 characters in length',
            bind_pointAddr: 'Please enter in the point address',
            bind_pointaddrLength: '1-100 characters in length',
            bind_ruleMap: 'Enter map site',
            bind_messageSucc: 'Binding successful!',
            bind_messageLose: 'Binding failure!',
            bind_foundSucc: 'Created successfully!',
            bind_foundLose: 'Creation failed!',

            // 预警规则
            alarmRule_OpenState: 'Open state',
            alarmRule_isOpen: 'Enable or not',
            alarmRule_event: 'Event type',
            alarmRule_productRank: 'Product grade',
            alarmRule_entityRank: 'Unit',
            alarmRule_siteRank: 'Site level',
            alarmRule_deviceRank: 'Device',
            alarmRule_openState: 'Confirm changing alert rule state?',
            alarmRule_drawerTitle: 'Associated users',
            alarmRule_sysUser: 'System users',
            alarmRule_TemUser: 'Temporary users',

            // 联动设备
            linkRule_linkDeviceCode: 'Enter complete linkage device code',
            linkRule_linkDevice: 'Linkage device',
            linkRule_triggerDevice: 'Trigger device',
            linkRule_triggerEvent: 'Trigger event',
            linkRule_linkeOrder: 'Linkage command',
            linkRule_allState: 'All state',

            // 推送情况
            push_aliPhoneRecords: 'Aliyun phone records',
            push_linePhoneRecords: 'Dedicated phone records',
            push_aliSmSRecords: 'Aliyun SMS records',
            push_appPush: 'APP push records',

            // Binding personnel
            bindUser_Title: 'Binding personnel',
            bindUser_userName: 'User name',
            bindUser_isFirstBind: 'Whether to bind the first device',
            bindUser_firstBind: 'First binding',
            bindUser_ISControls: 'Whether the operation permission is available',
            bindUser_canControls: 'operability',
            bindUser_noCOntrols: 'inoperable',
            bindUser_Tips: 'Are you sure you want to change the permissions?',
            bindUser_editRole: 'Modify permission',

            basice_DetectorData: 'Detector data',
            basice_DetectorNumber: 'Detector number',
            basice_SensorType: 'Sensor Type',
            basice_report: 'Reporting cycle',
            basice_4gGas: '4g industrial gas type',
            basice_wireLess: 'Wireless module type',
            edition: 'edition',

            device_allNnum: 'Device count',
            OwnDevice: 'own',
            NoDevice: 'No',
            siteImg: 'Picture of site',
            pointIMg: 'Point picture'

        },

        // User
        userManage: {
            // 用户信息基本看到
            details_edit: 'Modify',
            details_userNewsTitle: 'User information',
            details_saveEdit: 'Save modification',
            details_editPawword: 'Change password',
            details_loginName: 'Account',
            details_name: 'Name',
            details_phone: 'Contact phone',
            details_sex: 'Gender',
            details_roleID: 'Role',
            details_Entity: 'Unit',
            details_limitsName: 'Permission',
            details_createTime: 'Creation time',
            details_upDateTime: 'Update time',
            details_WeChatOfficialID: 'Wechat public account ID',
            details_WechatID: 'Wechat ID',
            details_isLogin: 'Account login or not',
            details_Mna: 'man',
            details_woman: 'woman',

            // 用户开关信息
            details_userSwitchTitle: 'User switch information',
            details_isLoginApp: 'Whether to allow login to the App',
            details_isReceivePush: 'Whether to receive App push',
            details_isLoginPc: 'Whether to allow you to log in to the PC',
            details_isReceiveSMS: 'Receive SMS or not',
            details_isREceivePhon: 'Receive calls or not',
            details_isReceiveOfficial: 'Whether to receive the public account push',
            details_isPcAlarmPush: 'Whether to receive the PC alarm push',
            details_isLoginProgram: 'Whether to allow login applet',

            // User information,未输入时提醒
            details_loginNamePla: 'Please enter your account',
            details_namePla: 'Please enter your name',
            details_phonePla: 'Enter contact phone number',
            details_sexPla: 'Please select gender',
            details_roleIDPla: 'Please select a role',
            details_EntityPla: 'Please select a unit',
            details_limitsNamePla: 'Please enter a permission name',
            details_WeChatOfficialIDPla: 'Please enter the WeChat official account ID',
            details_WechatIDPla: 'Please enter your wechat ID',
            details_isLoginPla: 'Please select the account to log in',
            details_roleNoSp: 'Role cannot be empty',
            // 用户信息校验提醒
            details_loginNameRule: 'The account cannot be empty',

            details_nameRule: 'The name cannot be blank',
            details_phoneRule: 'The contact number cannot be empty',
            details_phoneRuleTwo: 'Please enter the correct contact number',

            details_sexRule: 'Gender cannot be null',
            details_pawword: 'Password cannot be empty',
            details_pawwordTwo: 'The password must consist of letters, numbers and underscores',
            details_pawwordThree: 'Password must be 6-18 characters long',
            details_login_Name: 'The account must not be empty',
            details_login_name_two: 'The account must contain letters and underscores',
            details_pawwordFour: 'Please enter the password',
            details_pawwordfive: 'Please enter your password again',
            details_pawwordsix: 'The two entered passwords are inconsistent!',
            details_EntityRule: 'The owning unit cannot be empty',
            details_isLoginRule: 'Account login state cannot be empty',

            // 用户开关信息,未输入时提醒
            details_isLoginAppPla: 'Please select whether to enable app login',
            details_isReceivePushPla: 'Select to receive App alarm notifications',
            details_isLoginPcPla: 'Select PC login permission',
            details_isReceiveSMSPla: 'Select to receive SMS',
            details_isREceivePhonPla: 'Select to receive calls',
            details_isReceiveOfficialPla: 'Select to receive public account push',
            details_isPcAlarmPushPla: 'Select to receive PC alert push',
            details_isLoginProgramPla: 'Select to allow Mini Program login',

            // 用户开关信息,校验提醒
            details_isLoginAppRule: 'App login cannot be empty',
            details_isReceivePushRule: 'APP alarm push cannot be empty',
            details_isLoginPcRule: 'PC login cannot be empty',
            details_isReceiveSMSRule: 'SMS reception cannot be empty',
            details_isREceivePhonRule: 'Phone reception cannot be empty',
            details_isReceiveOfficialRule: 'Subscription to public account push notifications is required',
            details_isPcAlarmPushRule: 'PC Alerts Reception Required',
            details_isLoginProgramRule: 'Mini Program Login Required',

            details_confimEdit: 'Confirm',

            user_roleID: 'Role ID',
            user_rolePla: 'Role search',
            user_sexSearPla: 'Gender Search',
            user_moreSear: 'More search',
            user_entityNamePla: 'Enter Unit Name',
            user_confirmPaword: 'Comfirm password',
            user_addUser: 'Add User',
            user_cipher: 'Password',
            user_submit: 'Submit',
            user_role: 'Role',
            user_sureDelUser: "Are you sure to delete the user?",
            user_notDelOwn: "Deleting yourself is not supported",
            user_delSuccess: "successfully delete",
            user_delError: "fail to delete",
        },

        // Alarm receiving platform
        alarmPlatform: {
            warchMan: 'watch',
            connectState: 'Connection state',
            succeed: 'Successful',
            lose: 'Lose',
            deviceAllData: 'Total devices',
            todayAlarm: 'Alarms of today',
            pushcondition: 'Push situation',
            alarmHandleHandrail: 'Alarm handling ',
            deviceBasiceNews: 'Device Info',
            deviceData: 'Device data',
            alarmReason: 'Reason',
            alarmReasonPla: 'Select Alarm Reason',
            remark: 'Remark',
            handleAlarm: 'Handle',
            rest: 'Reset',
            leisure: 'leisure',
            alarmRecords: 'Alarm record',
            video: 'video',

            table_deviceNumber: 'Device code',
            table_alarmTime: 'Time',
            table_address: 'Site',
            deviceModelNum: 'Model',
            deviceType: 'type',
            entity: 'Unit',


            handle: 'handle',
            untreatedNews: 'Unprocessed information',
            dutyLog: 'Duty log',
            close: 'Close',

            planeImg: 'Floor plan',
            TwoDMap: '2D map',
            threeDMap: '3D map',
            threeDModeling: '3D model',
            videoWatch: 'Video',
            relevanceVideo: 'device-Associated video',
            dragCom: 'drag',

            timePushRecords: 'Current time push record',
            AliSmS: 'Aliyun SMS',
            AliVoice: 'Aliyun voice',
            PruvateTelephone: 'private telephone',
            appPush: 'APP message push',

            Mess_selectAlarm: 'Select alarm event first!',
            mess_handleAlarmLose: 'Handling alarm failed!',
            mess_handleAlarmSuccess: 'Alarm handling successful!',
            mess_handleNewAlarm: 'Please handle the current alarm first!',
            mess_noVideo: 'Camera device,No video!',

            rulesHandleWay: 'Select processing method',
            rulesRemark: 'Please enter remarks',

            selectKey: 'Button test',
            selectSmoke: 'Smoke test',
            selectGas: 'Gas test',
            selectReharsal: 'Drill',
            selectFireHazard: 'Fire',
            selectOther: 'Other',
            selectAll: 'All',
            selectUnconfirmed: 'Unconfirmed',

            // 报警记录
            alarmRecord_btn: 'Return device alarm record',
            alarmRecord_searSiteName: 'Enter site name',
            alarmRecord_SearTypeName: 'Enter type name',
            alarmRecord_alarmAllNum: 'Total alarms',
            alarmRecord_todayAlarm: 'Alarms today',
            alarmRecord_processor: 'Handler',
            alarmRecord_handleTime: 'Handling time',
            alarmRecord_handlingSuggestion: 'Remark',
            alarmRecord_histouricalRecord: 'History record',
            alarmRecord_handleTitle: 'Handle',
            alarmRecord_selectPla: 'Select alarm reason',
            alarmRecord_alarmReason: 'Enter alarm remarks',
            alarmRecord_handlecomplete: 'Processing complete!',
            alarmRecord_handleLose: 'Processing failure!',
            alarmRecord_check_TypeMessage: 'Select alarm reason',
            alarmRecord_alarmRemarks: 'Enter alarm remarks',

            // Duty log
            dutyLog_add: 'Add',
            dutyLog_FillMan: 'Informant',
            dutyLog_FillContent: 'enter in the content',
            dutyLog_attachmentsOne: 'attachments1',
            dutyLog_attachmentsTwo: 'attachments2',
            dutyLog_attachmentsThree: 'attachments3',
            dutyLog_download: 'Download',
            dutyLog_empty: 'vacancy',
            dutyLog_DialogTitle: 'Add log',
            dutyLog_addFormLabel: 'enter in the content',
            dutyLog_addFormPla: 'Enter the report content',
            dutyLog_addFormRule: 'Report content cannot be empty',

            message_alarmLose: 'Failed to retrieve playback address!',
            message_alarmLoseAndGuanlian: "Failed to obtain the associated camera. Procedure!",
            message_plangImgLose: "No pictures",
            message_alarmLoseNOvide: "Play failure!There is no associated camera",
            message_noRelevanc: 'No associated cameras',
            message_alarmBlockleisure: 'No alarm data',

        },

        // 推送记录
        pushRecords: {
            public_time: 'time',
            public_pushMeassger: 'Push content',
            public_deviceNum: 'Device code',

            AliSmsPhone: 'Mobile phone',
            AliSmsTime: 'Occurrence time',
            AliSmsEventType: 'Event type',
            AliSmsEvent_alarm: 'Alarm event',
            AliSmsEvent_fault: 'Fault event',
            AliSmsEvent_heartbeat: 'Heartbeat event',
            AliSmsEvent_deviceOffLine: 'offline event',
            AliSmsEvent_deviceOnLine: 'On-line event',
            AliSms_newMessage: 'Message content',

            // 语音推送
            AliVoiceTime: 'time',
            AliVoicePush: 'Push content',

            // 公众号
            publicAccount_template: 'Template ID',
            publicAccount_pushUser: 'User ID of the push',
            publicAccount_errmsg: 'Result description',
            publicAccount_msgid: 'Message ID',
            publicAccount_deviceNum: 'Device code',
            publicAccount_ruleId: 'Rule ID',

            // 专线
            specialLine_answerState: 'Answering state',
            specialLine_userNoAnswer: 'User didn`t  answer',
            specialLine_refuseAnswer: 'User declined the call',
            specialLine_answerButFinish: 'User answered and hung up',
            specialLine_answerButPassivityFinish: 'User answered and was disconnected passively',
            specialLine_answerTIme: 'Answering time',
            specialLine_noAnswer: 'Missed call',
            specialLine_overTime: 'End time',

            // APP push
            appPush_pushResult: 'Push result',
            appPush_pushRState: 'Push state',

            // 阿里云电话记录
            aliPho_phoneSearPla: 'Enter phone number search',
            aliPho_rulesPhone: 'Enter a 11-digit phone format'
            // aliPho_
            // aliPho_
            // aliPho_
            // aliPho_
        },
        // 事件类型
        eventType: {
            eventTitle: 'Event type',
            eventIsOpen: "Event Switch",
            alarmEvent: 'Alarm event',
            faultEvent: 'Fault event',
            heartbeatEvent: 'Heartbeat event',
            OffLineEvent: 'offline event',
            OnLineEvent: 'On-line event',
        },
        // 设备管理-故障记录
        faultRecords: {
            backFaultRecords: 'Return device fault records',
            handleStatePla: 'Select handling state',
            handleDeviceState: 'Select device state',
            historicalRecord: 'History record',
            handle: 'Processed',
            noHandle: 'untreated'
        },
        // 设备管理-报警记录
        alarmRecirds: {
            backAlarmRecords: 'Return alarm record',
            selectAlarmPla: 'Select alarm reason',
            pushState: 'Push situation',
            dialogTitle: 'Handle',
            alarmCausePla: 'Select alarm reason',
            AllAlarmCause: 'All alarm reasons',
            pushRecords: 'Push records',
            isCollectTips: 'Unfocus this device?',
            cancelCollect: 'Unfocus',
            message_cancelCollectSucc: 'Unfocus successful',
            message_cancelCollectLose: 'Failed to unfocus',
        },


        // 安装管理-安装点管理
        installPoint: {
            pointName: 'Point name',
            pointNamePla: 'Enter point name',
            deviceCoding: 'Device code',
            deviceCodingPla: 'Enter device code',
            pointAddrPla: 'Enter point address',
            installNews: 'install information',
            saveEdit: 'Save modification',
            verifyEdit: 'Confirm modification',
            collectPointTips: 'Focus this point?',
            colloect: 'Focus',
            drawerTitle: 'install  details',
            dialog: 'Add install points',
            rules_noSpecial: 'Cannot contain special characters!',

            form_deviceNum: 'Code:',
            form_deviceNumPla: 'Enter device code',
            form_floorNum: 'Floor:',
            form_floorNumPla: 'Enter the number of floors',
            form_pointAddrPla: 'Enter the site address',
            form_entity: 'Unit:',
            form_pointAddr: 'Point address:',
            form_siteName: 'Site name:',
            form_PointName: 'Point name:',
            form_entityPla: 'Select the unit',
            form_naem: 'Name',
            message_succ: 'Focused successfully',
            message_lose: 'Failed to focus',
            // form_

            img_title: 'Point Images',
            img_deleteTips: 'Confirm delete this image?',
            img_messageSucc: 'Upload successful!',
            img_messageLose: 'Upload failed!',
            img_messageCancel: 'Cancelled upload',
            img_tips: 'Confirm or cancel the current site first!',
            img_pointSucc: 'Site saved successfully!',
            img_pointLose: 'Site save failed!',
            point_delete_tips: 'Delete site and restore associated devices to unbound state? Confirm？',
        },

        // 安装管理-场所管理
        siteManage: {
            search_site: 'Site name search',
            search_siteAddr: 'Site address search',
            addBtn: 'Add',
            site_collectTips: 'Foucs this site?',
            site_drawerTitle: 'Site details',
            site_news: 'Site information',
            site_contactPerson: "Owner's Name:",
            site_contactPersonPhone: "Owner's Phone Number:",
            site_log: 'Longitude:',
            site_lng: 'Latitude:',
            site_contactPersonName: 'Contact Name:',
            site_contactPersonNamePla: 'Enter contact name',
            site_contactPersonNum: 'Contact phone',
            site_contactPersonNumPla: 'Enter contact phone number',
            site_imgNews: 'Images',
            site_contactPersonPla: 'Enter contact name',
            site_contactPersonPhonePla: 'Enter contact telephone number',
            site_siteAddr: 'Site address:',
            site_logPla: 'Enter longitude',
            site_lngPla: 'Enter latitude',
            site_ruleSiteName: 'Site name cannot be empty',
            site_ruleSiteAddr: 'Site address cannot be empty',
            site_ruleContactName: 'The contact name cannot be empty',
            site_rulePhone: 'The contact number cannot be empty',
            site_rulePhoneTwo: 'Failed to add data! Pay attention to the phone format!',
            site_modelID: 'Model ID',
            site_modelNnme: 'Model name',
            site_modelCreateTime: 'Creation time',
            site_modelNamePla: 'Enter model name',
            site_collectSucc: 'Focused successfully',
            site_collectLose: 'Failed to focus',
            site_editSucc: 'Focused successfully',
            site_editLose: 'Failed to focus',
            site_cancelAddSite: 'Cancel addition!',
            site_delete_tips: 'Confirm site deletion? All associated data will be removed？',
        },

        // 安装管理_楼层管理
        floorManage: {
            floor_collectTips: 'Focus this floor?',
            floor_drawer: 'Floor details',
            floor_news: 'Floor information',
            floor_dialogTItle: 'Add floor',
            floor_createTIme: 'Creation time:',
            floor_floor: 'floor:',
            floor_floorPla: 'Please enter floor',
            floor_messageSucc: 'Focused successfully',
            floor_messageLose: 'Failed to focus',
            floor_floorNum: 'Floor',

            add_floor: 'Floor',
            add_site: 'Site',
            add_reset: 'Reset',
            add_rulerNum: 'Enter numeric type',
            add_flooRules: 'Floor cannot be empty',
            add_siteRules: 'Site cannot be empty',

            img_addSucc: 'Upload picture successfully!',
            img_addLose: 'Upload picture failed!',
            floor_delete_tips: 'Confirm deletion of floor?',
        },

        // 收藏夹-楼层收藏
        collectFloorManage: {
            floor_numPla: 'Floor search',
            floor_cancelCollectTips: 'Unfocus this floor?',
            floor_uncollect: 'Unfocus',
            floor_dialogTitle: 'Add Device Details',
            floor_createdTimePla: 'Enter creation time',
            floot_floorNum: '',
            floor_siteNamePla: 'Please select a site name',
            floor_siteAddrPla: 'Site address',
            message_uncollectSucc: 'Uncollect successfully!',
            message_uncollectLose: 'Uncollect failed!',
            message_floorNoSpeac: 'Floor data cannot be empty!'
        },

        // 收藏夹-安装点收藏
        collectPoint: {
            point_searchName: 'Site name search',
            point_collectTips: 'Unfocus this site?',
            point_dialogTitle: 'Add mounting points',
            point_relevanceSite: 'Associated site',
            point_uncollectSucc: 'Cancel successful!',
            point_uncollectLose: 'Cancel failed!',
        },

        // 收藏夹-场所收藏
        collectSite: {
            site_collectTips: 'Unfocus this site?',
            site_cancelCollect: 'Unfocus',
            site_rulesLon: 'Longitude cannot be empty',
            site_rulelat: "Latitude cannot be empty",
            stie_cancelSiteSucc: 'Unfocus successful',
            site_cancelSiteLose: 'Failed to unfocus',

            basic_deleteImgTips: 'Confirm delete this image?',
            basic_UpImgSucc: 'Upload successful!',
            basic_UpImgLose: 'Upload failed!',
            basic_cancelUp: 'Cancelled upload!',

            map_TiTle: 'Map',
            map_editPoint: 'Modify Coordinates',
            map_confirmPoint: 'Save',
            map_lon: 'Longitude',
            map_lng: 'Latitude',
            map_siteNamePla: 'Enter the site name to search',
            map_tips: "Mobile blue water droplet image",
        },

        // Production management-设备模型
        deviceProfile: {
            profile_modelName: 'Model name',
            profile_modelNamePla: 'Enter model name',
            profile_enttity: 'Unit name',
            profile_entityPla: 'Enter unit name',
            profile_tableModelName: 'Model name',
            profile_drawerTitle: 'Device model details',
            profile_basiceLabel: 'Information',
            profile_dialogTitle: 'Add Device Model',

            addForm_IDPla: 'Please enter ID',
            addForm_appkeyPla: 'Please enter aep_appkey',
            addForm_appsecretPla: 'Please enter aep_appsecret',
            addForm_product_idPla: 'Please enter product_id',
            addForm_command_idPla: 'Please enter command_id',
            addForm_masterkey: 'Please enter masterkey',
            addForm_protocol_type: 'Please enter protocol_type',
            addForm_protocol_typeRule: 'Enter protocol_type',

            addForm_expire_time: 'Command cache time',
            addForm_expire_timePla: 'Please enter instruction cache time data',
            addForm_onenet_apikeyPla: 'Please enter onenet_apikey',
            addForm_BC260Y: 'BC260Y device',
            addForm_BC260YPla: 'Please enter BC260Y device',
            addForm_Platform: 'Operator platform',
            addForm_PlatformPla: 'Please enter carrier platform',
            addForm_creator: 'Creator',
            addForm_creatorPla: 'Please enter the name of the creator',
            addForm_onenerPla: 'Please enter move onener',

            rules_modelNameNoSpace: 'The model name cannot be empty',
            rules_modelNameLength: 'The data length cannot exceed 20',
            rules_maxLength: 'The data length cannot exceed 50',
            rules_entityNoSpace: 'The owning unit cannot be empty',
            rules_platform: 'The carrier platform cannot be empty',

            message_entity: 'The unit cannot be empty!',
            message_addDeviceSucc: 'Device model added successfully!',
            message_addDeviceLose: 'Add model failure!'

        },

        // 生产管理-设备型号
        deviceModel: {
            modelName: 'Model number name',
            model_NamePla: 'Enter Model Name',
            table_modelName: 'Device name',
            table_model: 'Ownership model',

            model_drawer: 'Device Model Details',
            model_dialog: 'Add Device Model',

            rules_modelNoVacancy: 'Device model cannot be empty',
            form_deviceID: 'Device Model ID',
            form_deviceIDPla: 'Enter device model ID',
            form_modelCodingPla: 'Enter model code',
            form_modelIntro: 'Model description',
            form_modelIntroPla: 'Enter model description',
            form_deviceModel: 'Model',
            form_LowVoltage: 'Minimum voltage',
            form_LowVoltagePla: 'Enter minimum voltage',
            form_LowVoltageNum: 'Low voltage number',
            form_LowVoltageNumPla: 'Enter low voltage occurrences',
            form_lowSignal: 'Minimum signal value',
            form_lowSignalPla: 'Enter minimum signal value',
            form_SiganWeakNum: 'Signal weak times',
            form_SiganWeakNumPla: 'Enter signal weak count',
            form_deviceMoelPlaNo: 'Enter device model',

            ruler_modelName: 'Model number name cannot be empty',
            ruler_modelLength: 'The value contains 1 to 20 characters',
            ruler_modelCoding: 'Model code cannot be empty!',
            ruler_modelIntro: 'Model introduction cannot be empty!',
            ruler_devcieModel: 'Device model data cannot be empty!',

            message_addModelSucc: 'Model Add  succeed!',
            message_addModelLose: 'Failed to add a model!',
        },

        // Production management-型号指令
        modelCommand: {
            modelNum_orderName: 'Command Name',
            modelNum_orderNamePla: 'Enter command name',
            modelNum_modelNum: 'Model',
            modelNum_editOrderTitle: 'Edit Model Instruction',
            modelNum_addOrderTitle: 'Add Model Instruction',
            modelNum_orderArgument: 'Command parameters',
            modelNum_copy: 'Copy',
            modelNum_copySucc: 'Successful replication',
            modelNum_JsonText: 'Edit template parameters',
            modelNum_modelPla: 'Enter Model Name',
            modelNum_ruleOrder: 'Command name cannot be empty!',
            modelNum_ruleModelNum: 'The model data cannot be empty',
            modelNum_orderArgumentPla: 'Instruction parameter data cannot be empty',

            message_addOrderSucc: 'Add instruction succeeded!',
            message_addOrderLose: 'Failed to add instruction!',
            message_editOrderSucc: 'Modified instruction succeeded!',
            message_editOrderLose: 'Failed to modify instruction!',
        },

        // 生产管理-设备类型
        deviceType: {
            type_name: 'Type name',
            type_namePla: 'Enter type name',
            type_coding: 'Type code',
            type_codingPla: 'Enter Device Type Code',
            type_drawerTitle: 'Device type details',
            type_dialogTitle: 'Newly increase device type',
            type_id: 'Type ID',
        },

        // Production management-设备质检
        deviceCheck: {
            check_checkMid: 'The device is undergoing quality inspection',
            check_needCheck: 'Devices to be inspected',
            check_needCheckPla: 'Please enter device IMEI...',
            check_lowSignal: 'Minimum signal strength',
            check_lowVoltage: 'Minimum voltage(mv)',
            check_checkDayHearbeat: 'Whether to check the 24-hour heartbeat',
            check_qualityTesting: 'Whether to check the quality inspection data',
            check_alarmData: 'Whether to check the alarm data',
            check_normalDevice: 'Normal device',
            check_importClient: 'Import customers',
            check_clearTableTips: 'Sure to empty the normal form?',
            check_clearTable: 'Empty the form',
            check_abnormalDevice: 'Exception device',
            check_abnormalNews: 'Exception information',
            check_selectDevcieType: 'Please select the device type',
            check_deviceFormatLose: 'Device format is not sought!',
            check_qualitySucc: 'Device quality inspection completed!',
            check_qualityLose: 'Device inspection failure!',
            messge_qualitySucc: 'The device has been inspected!',
            check_desc_uncheck: "Unchecked",
            check_desc_success: "Normal",
            check_desc_error: "Abnormal",
        },

        // 生产管理_质检记录
        checkRecord: {
            record_result: 'All Inspection Records',
            record_normal: 'Normal',
            record_abnormal: 'Exceptions',
            record_checkLog: 'Check Log',
            record_checkTime: 'Quality inspection time',
            record_checkConditions: 'Quality inspection details',
            record_qualityNormal: 'Quality inspection normal',
            record_qualityAbnormal: 'Quality inspection exception'
        },

        // 生产管理_设备导入:{
        deviceImport: {
            import_plarform: 'Platform',
            import_plarformPla: 'Select the platform and search',
            import_type: 'Import type',
            import_import: 'To lead into',
            import_signOut: 'Sign OUT',
            import_importEntity: 'Import units',
            import_singIngPlafrom: 'Registration platform',
            import_Time: 'Import time',
            import_Log: 'Import log',
            import_state: 'Import situation',
            import_download: 'Download attachment',
            import_dialogTitle: 'Import device',
            import_singIngPlafromPla: 'Select registration platform',
            import_expireTime: 'Expiration time',
            import_selectTime: 'Select date',
            import_drawerTitle: 'Device import Details',
            import_deviceState: 'Device import situation',
            import_signInResult: 'Registration results',
            import_successful: 'Successful',
            import_lose: 'Lose',
            import_signInLog: 'Log of logouts',

            import_signInTime: 'Registration time',
            import_allType: 'All import types',
            import_deviceImport: 'Device import',
            import_deviceSignOut: 'Device logout',
            import_importSucc: 'Import successfully!',
            import_importLose: 'Import failure!',

            message_devcieModelNum: 'Device model cannot be empty!',
            message_signInPlarform: 'Registration platform cannot be empty!',
            message_importEntity: 'Imported unit cannot be empty!',
            message_devcieType: 'Device type cannot be empty!',
            message_importNo: 'Import file cannot be empty!',
            // message_
            import_file_type: "Only .xlsx files can be uploaded"
        },

        //  Production management-注册记录
        registerRecord: {
            register_platformPla: 'Select registration platform',
            register_allResult: 'All registration results',
            register_registerSucc: 'Registration success',
            register_registerLose: 'Registration failed',
        },

        // Production management-注销记录
        logOutRecord: {
            logOut_platform: 'logout platform',
            logOut_result: 'logout result',
            logOut_resultLog: "logout Log",
            logOut_time: 'logout time',
        },

        // 预警管理-预警规则
        alarmRule: {
            alarm_radius: 'Scope level',
            alarm_opentState: 'Open state',
            alarm_searPla: 'Enter device code/unit to search',
            alarm_deleteTips: 'Confirm to delete this alert rule?',
            alarm_delete: 'Delete',
            alarm_drawerTitle: 'Add alert rule',

            add_titleOne: 'Create a rule',
            add_titlePlaOne: 'Select alert scope, set alert recipients',
            add_titleTwo: 'Add alert recipients',
            add_titlePlaTwo: 'Add system users associated with the rule',
            add_titleThreePla: 'Add temporary users associated with the rule',
            add_open: 'Open',
            add_alarmObject: 'Alarm object',
            add_previous: 'Previous step',
            add_next: 'Next step',
            add_deviceID: 'Device ID',
            add_deviceName: 'Device name',
            add_deviceOther: 'Other information',
            add_devicePla: 'Enter device code',
            add_eventTypeNoEmpty: 'Event type required',
            add_selectAlarm: 'Select a warning object',
            add_createrAlarmSucc: 'Alert rule created successfully!',
            add_createrAlarmLose: 'Failed to create alert rule!',
            add_entityRank: 'Unit',
            add_deviceRank: 'Device',

            sys_title: 'System user list',
            sys_searName: 'Name search',
            sys_phoneName: 'Mobile number search',
            sys_searBtn: 'Search',
            sys_clearSear: 'Clear criteria',
            sys_name: 'Name',
            sys_phone: 'Contact phone',
            sys_role: 'Role',
            sys_addTips: 'Add this user to the alert rule?',
            sys_add: 'Add',
            sys_cardTitle: 'System users with associated rules',
            sys_RemoveTips: 'Remove this user from the alert rule?',
            sys_removerBtn: 'Remove',
            sys_phoneFormat: 'Enter valid 11-digit mobile format',
            sys_addSucc: 'Add successfully!',
            sys_addLose: 'Add failure!',
            sys_romoverSucc: 'Removed successfully!',
            sys_removerLose: 'Removal failure!',

            tem_title: 'Temporary user list',
            tem_receivePhone: 'Receive calls or not',
            tem_receiveSms: 'Receive SMS or not',
            tem_cardTitle: 'Associated temporary users',
        },

        // 预警规则-联动规则
        linkRules: {
            link_trigger: 'Trigger device',
            link_triggerPla: 'Enter complete trigger device code',
            link_linkage: 'Linkage device',
            link_editLinkageTips: 'Confirm device linkage rule state modification?',
            link_dialogTitel: 'device linkage rules',

            message_openDetailsLose: 'Failed to open details',
            message_delectSucc: 'Deleting data succeeded!',
            message_delectLose: 'Failed to delete data',

            add_triggerCondition: 'Trigger conditions',
            add_triggerConditionTips: 'Clear trigger conditions?',
            add_deviceCoding: 'code',
            add_eventType: 'Event type',
            add_isOpen: 'Enable or not',
            add_clearLinkageTips: 'Clear linked devices?',
            add_executeMOvement: 'Execute action',
            add_executeMOvementTips: 'Clear execution actions?',
            add_devcieOrder: 'Device command',
            add_saveLink: 'Save rule',
            add_setTrigger: 'Set the trigger condition',
            add_setLinkage: 'Set the linkage device',
            add_triggerEvent: 'Trigger device',
            add_select: 'Select',
            add_linakageCommand: 'Commands for linkage devices',
            add_deviceModelNum: 'Model',

            message_selectTrigger: 'Select triggering device!',
            message_triggerEvent: 'Select triggering event type!',
            message_lingkaeDevice: 'Select linked device!',
            message_lingkaeCommand: 'Select linked command!',
            link_deleteTips: 'Confirm deletion of this linkage rule?',
        },

        // 预警管理-临时用户
        temporarilyUser: {
            tem_receiveSms: 'SMS reception',
            tem_receivePhone: 'Phone reception',
            tem_name: 'Temporary user name',
            tem_UserPhone: 'Temporary users phone number',
            tem_delectTips: 'Confirm deletion of this temporary user?',
            tem_drawerTitle: 'Temporary user details',
            tem_dialogTitle: 'Add temporary user',
            tem_rulerNoSpecialStr: 'Cannot contain special characters!',
            tem_rulerPhoneMax: 'The phone must be 11 digits',
            tem_receive: 'Receive',
            tem_noReceive: 'Do not receive',
            tem_phone: 'Phone',
            tem_isReceiveSms: 'Receive SMS or not',
            tem_isReceivePhone: 'Receive calls or not',
            tem_creater: 'found',

            rule_length: 'The data length is 11 characters',
            rule_UserNameNoSpecial: 'The temporary user cannot be empty',
            rule_phoneNoSpecial: 'Phone number cannot be empty',
            rule_receiveSms: 'Select SMS reception',
            rule_receivePhone: 'Select call reception',
            rule_createrUserID: 'Creator ID',

            message_addSucc: 'New user added successfully!',
            message_addLose: 'Failed to add new user!',
            // tem_
        },

        // 预警管理-型号模板
        modelNum: {
            model_notificationPla: 'Notification Type',
            model_templateName: 'Template Name',
            model_templateNamePla: 'Enter Template Name',
            model_deleteTips: 'Delete this Notification Template?',
            model_relevanceModel: 'Associated Models',
            model_drawerTitle: 'Models Associated with Template',
            model_dialogTitleOne: 'Template Details',
            model_dialogTItleAdd: 'Add Template',
            model_templatArgument: 'Template Arguments',
            model_save: 'Save',
            model_NewCreater: 'Create Now',
            model_SelectNotification: 'Select Notification Type',
            model_selectEvent: 'Select Event Type',
            model_ruleTemplatArgument: 'enter in Template Arguments',

            model_phone: "phone",
            model_note: "note",
            model_PCPush: "PC Push",
            model_AppPush: "APP Push",
            model_OfficialAccounts: "Official Accounts",

            message_saveTemplat: 'Save Template Arguments first',
            message_createrSucc: 'Creation Successful!',
            message_createrLose: 'Creation Failed!',

            relevance_title: 'Device Model List',
            relevance_modelName: 'Model Name',
            relevance_modelNumIntro: 'Model Introduction',
            relevance_addTips: 'Add this model to the template?',
            relevance_cardTitle: 'Models Associated with Template',
            relevance_rmoeverTips: 'Remove this model from the template?'
        },

        // Account management-单位管理
        entityManage: {

            entity_name: 'Unit',
            entity_namePla: 'Enter Unit Name',
            entity_addr: 'Unit Address',
            entity_addrPla: 'Enter Unit Address',
            entity_platformName: 'Platform Name',
            entity_platformNamePla: 'Enter Platform Name',
            entity_coding: 'Unit ID',
            entity_details: 'Details',
            entity_addJunior: 'Add Subordinate Unit',
            entity_drawerTitle: 'Unit Details',
            entity_entityNews: 'Unit Information',
            entity_confirmEdit: 'Confirm Edit',
            entity_logo: 'Unit Logo',
            entity_addEntity: 'Add Unit',
            entity_platformLogo: 'Logo',
            entity_superiorsName: 'Parent Unit ',
            entity_oneImg: 'Only one logo can be uploaded!'
        },

        // 巡检巡查-巡检任务
        patrolTask: {
            task_naemSear: 'Search for Inspector Name',
            task_ssear: 'Search',
            task_chargePerson: 'Leader',
            task_desc: 'Description',
            task_type: 'Type',
            task_state: 'State',
            task_notStarted: 'Not Started',
            task_underWay: 'In Process',
            task_UnCompleted: 'Uncompleted',
            task_completed: 'Completed',
            task_name: 'Inspector Name',
            task_phone: 'Inspector Phone',
            task_createrTime: 'Start Time',
            task_deleteTips: 'Delete this task?',
            task_deleteBtn: 'Delete',
            task_dialogTitle: 'Add Inspection Task',
            task_drawerTitle: 'Inspection Task Details',
            // task_

            add_name: 'Inspector',
            add_roleName: 'Role Name',
            add_phone: 'Phone Number',
            add_UserNamePla: 'Enter Username to Search',
            add_starTime: 'Start Time',
            add_endTime: 'End Time',
            add_taskLabel: 'Associated Sites',
            add_selectTime: 'Select Date and Time',
            add_descPla: 'Enter Task Description',
            add_submitFormBtn: 'Create Now',
            add_rulesTaskType: 'Select Task Type',
            add_rulesPatrolMan: 'Select Inspector',
            add_rulesOpenTime: 'Select Start and End Time',
            add_rulesEntity: 'Select Unit',
            add_ruelsDesc: 'Enter Task Description',
            add_addLose: 'Failed to Add!',
            add_taskID: 'ID',
            add_rulesSite: 'Select Site',

            basic_hiddenTRoubleType: 'Types of security risks',
            basic_patrolName: 'Name of inspector',
            basic_patrolPhone: 'Inspector phone number',
            basic_patrolNameSex: 'Gender of inspector',
            basic_alterTime: 'Change time',
            basic_taskDesc: 'Task description',
            basic_cardTitle: 'Inspection records',
            basic_createUser: 'creator',
            basic_patrolState: 'Inspection situation',

            basic_dialogTitle: 'Inspection situation',
            basic_dangerLevel: 'Danger level',
            basic_noDanger: 'Nonhazardous',
            basic_oneDanger: 'Gimme danger',
            basic_twoDanger: 'Secondary danger',
            basic_threeDanger: 'Tertiary danger',
            basic_handleState: 'Processing state',
            basic_processed: 'Processed',
            basic_pending: 'Pending',
            basic_abnormalTypeName: 'Exception type name',
            basic_checkImg: 'Inspection picture',
            basic_AfterAbarbeitungImg: 'Picture after abarbeitung',
            basic_noAbarbeitungImg: "No abarbeitung picture",
            basic_uploadingServer: 'Confirm upload server',
            basic_abarbeitungImg: 'Abarbeitung picture',
            basic_messageOneImg: 'Select image to upload first',
            basic_messageOnlyUpImg: 'Only one image can be uploaded at a time!',

        },

        // 巡检巡查-巡检记录
        patrolRecord: {
            record_upLoadingServer: 'Upload server',
            record_dialogTitle: 'Add Inspection Record',
            record_site: 'Site',
            record_belongTask: 'Select Belonging Task',
            record_inspectionResult: 'Inspection Result',
            record_inspectionResultPla: 'Select Inspection Result',
            record_problemDesc: 'Problem Description',
            record_ruleSite: 'Select Related Site',
            record_ruletask: 'Select Task',
            record_RuleInspectionResult: 'Select Inspection Result',
            record_ruleRroblemDesc: 'Enter Problem Description'

        },

        // 巡检巡查-任务类型
        taskType: {
            type_deleteTips: 'Delete this task type?',
            type_deleteBtn: 'Delete',
            type_taskTypeName: 'Task type name',
            type_rules: 'Please enter an exception type name',
            type_dialognTitle: 'Modify task details',
            type_addTitle: 'Add task type',
            type_addLose: 'Add  fail!',

            type_language: 'Language type',
            type_languagePla: 'Select Language type',
            type_languageRule: 'Enter Language type',
        },

        // 巡检巡查
        patrolAll: {
            inspectImg: 'Inspection picture',
            hiddenTroubleBtn: 'Modify',
            hiddenTroubleBtnTips: 'Delete abnormal type?',
            addHiddenTrouble: 'Add Abnormal Type',
            editTitle: 'Edit Abnormal Type Name'
        },

        // 系统管理
        menuManage: {
            menu_title: 'Menu title',
            menu_name: 'Menu name',
            menu_path: 'Menu path',
            menu_grade: 'Menu level',
            menu_deleteTips: 'Sure to delete the menu?',
            menu_details: 'Details',
            menu_addMenu: 'Add Menu',
            menu_menuDeatails: 'Menu Details',
            menu_namePla: 'Enter menu name',
            menu_pathPla: 'Enter menu path',
            menu_titlePla: 'Enter menu title',
            menu_icon: 'Menu icon',
            menu_iconPla: 'Enter menu icon',
            menu_type: 'Menu type',
            menu_typePla: 'Enter menu type',
            menu_module: 'Menu component',
            menu_superiorsMenu: 'Superior menu',
            menu_ruleName: 'Menu name cannot be empty!',
            menu_rulePath: 'Menu path cannot be empty!',
            menu_ruleLengthMax: 'Length must be between 1 and 50 characters!',
            menu_ruleTitle: 'Menu title cannot be empty!',
            menu_ruleIcon: 'Menu icon cannot be empty!',
            menu_ruleType: 'Menu type cannot be empty!',
            menu_ruleTypeLength: 'Length must be between 1 and 10 characters!',
            menu_ruleGrade: 'Menu grade cannot be empty!',

            messge_deleteSucc: 'Delete menu successfully!',
            messge_deleteLose: 'Delete menu successfully!',
            messge_addMenuSucc: 'Add menu successfully!',
            messge_addMenuLose: 'Failed to add menu!',
            messge_editSucc: 'Modify menu successfully!',
            messge_editLose: 'Failed to modify menu!',
        },

        // 系统管理-角色管理
        roleManage: {
            role_name: 'Role name',
            role_namePla: 'Please enter the role name',
            role_deleteTips: 'Sure to delete this role?',
            role_addRole: 'Newly increase roles',
            role_editRole: 'Modifying Role Permissions',
            role_message: 'Unmodified permission!',

            role_roelMenu: 'Menu permissions',
            role_ruleName: 'Please enter the role name',
            role_messgeEditSucc: 'Modified successfully!',
            role_messgeEditLose: 'Modification failure!',
            role_messageAddSucc: 'Add  succeed!',
            role_messageAddLose: 'Add  fail!',

        },

        // 系统管理-导出记录
        exportRecords: {
            export_state: 'Data state',
            export_exportMidWay: 'exporting',
            export_exportSucc: 'export success',
            export_exportLose: 'export failure',
            export_downloadTips: 'Confirm to download this data?',
            export_downloadBtn: 'Download',
            export_deleteTips: 'Confirm to delete this data?',
            export_name: 'Data name',
            floorTitle: 'Floor Plan',
        },

        // 导航栏
        menubar: {
            workbench: 'Workbench',
            moreHelp: 'More help',
            backstageHomePaee: 'Home',
            cancel: 'NO',
            logOut: 'Confirm Logout?',
            backstageManage: 'Platform Management',
            SwitchLanguage: 'Switch language',
            equipped: "equipped",
            AllEquipment: "All equipment"
        },

        // Sub-platform-智慧消防
        fireFightingPlatform: {
            fire_title: 'Intelligent fire protection system',
            fire_VideoTItle: 'Video Surveillance Platform',
            fire_basicData: 'Basic data',
            fire_alarmData: 'Alarm data',
            fire_account: 'Account management',
            fir_map: 'Alarm map',

            left_alarmRecords: 'Alarm records',
            left_faultRecords: 'Fault records',
            left_deviceManage: 'Device management',
            left_siteUnit: 'Site/Building',
            left_floorList: 'Floor list',
            left_installPointManage: 'install point management',
            left_patrolTask: 'Patrol tasks',
            left_patrolRecords: 'Patrol records',
            left_patrolState: 'Hidden danger situation',
            left_patrolType: 'Types of security risks',
            left_entityManage: 'Unit management',
            left_userManage: 'User management',
            left_deviceStatistics: 'Device statistics',
            left_alarmStatistics: 'Alarm statistics',
            left_faultStatistics: 'Fault statistics',
            left_patrolTrouble: 'Hidden dangers/Patrol statistics',
            left_devcieStateStatistics: 'Device state statistics',
            left_troubleSatistics: 'Hidden danger statistics',
            left_installStatistics: 'install statistics',
            left_patrolStatistics: 'Patrol statistics',
            map_deviceModelNum: "Device model",
            map_deviceDetails: 'Device details',
            map_runData: 'Operational data',
            map_orderTips: 'Are you sure to enable this command?',
            map_Controls: 'Operation',
            map_signal: 'Signal strength',
            map_voltage: 'Voltage',
            map_temperature: 'Temperature',
            map_concentration: 'Concentration value',
            map_installTime: 'install time',
            map_noOrder: 'The device has no command to issue',

            map_floorNum: 'Floors quantity',
            map_installPoint: 'Positions quantity',
            map_go: 'Go',



            Gas_title: 'Gas Safety System',
            Production_title: 'Production Management System',
            patrol_title: 'Patrol Inspection Management System',
            patrol_taskType: 'Task Type',
            patrol_checkstate: 'Inspection state',
            patrol_type: 'Exception Type',

            Production_deviceModel: 'Model',
            Production_modelNum: 'Model Instructions',
            Production_PatrolRes: 'Quality Inspection Records',
            Production_devicePatrol: 'Device Inspection',
            Production_deviceImport: 'Device Import',
            Production_zuce: 'Registration Records',
            Production_zuxiao: 'logout Records'

        },

        xiXiangGasPlatform: {
            "xiXiang_PersonalMessage": "Personal Message",
            "xiXiang_Subscribers": "Subscribers",
            "xiXiang_Caption": "Caption",
            "xiXiang_CreationTime": "Creation time",
            "xiXiang_ReadingSituation": "Reading situation",
            "xiXiang_Particulars": "Particulars",
            "xiXiang_Operation": "Operation",
            "xiXiang_tipsReadMessage": "Are you sure you have read this message?",
            "xiXiang_tipsReadBtnTitle": "Read",
            "xiXiang_tipsIsRead": "Read",
            "xiXiang_tipsUnReadBtnTitle": "Unread",
            "xiXiang_Cancellations": "Cancellations",
            "xiXiang_MakeCertain": "Make certain",

            "xiXiang_HomePage": "home page",
            "xiXiang_EquipmentManagement": "equipment management",
            "xiXiang_WorkOrderManagement": "Work Order Management",
            "xiXiang_OperationAndMaintenanceReport": "Operation and Maintenance Report",
            "xiXiang_MerchantManagement": "merchant management",
            "xiXiang_systemManagement": "system management",
            "xiXiang_UserManagement": "user management",
            "xiXiang_MessageManagement": "Message Management",
            "xiXiang_LoginLog": "Login Log",
            "xiXiang_OperatingLog": "operating log",

            "xiXiang_BigSmartScreen": "Big Smart Screen",
            "xiXiang_DecisionMakingCommandAtAGlance": "Decision-making command at a glance",
            "xiXiang_RoutineInspection": "routine inspection",
            "xiXiang_ViewInspectionCompletionRates": "View Inspection Completion Rates",
            "xiXiang_ViewO&MReports": "View O&M Reports",
            "xiXiang_WorkOrderReporting": "Work order reporting",
            "xiXiang_ViewWorkOrderReporting": "View Work Order Reporting",

            "xiXiang_PercentageOfEquipment": "Percentage of equipment",

            "xiXiang_EquipmentOverview": "Equipment Overview",
            "xiXiang_TotalEquipment": "Total equipment",
            "xiXiang_CallThePoliceToday": "Call the police today.",
            "xiXiang_TroubleshootingToday": "Troubleshooting Today",
            "xiXiang_InstallationToday": "Installation Today",
            "xiXiang_MaintenanceToday": "Maintenance Today",
            "xiXiang_EquipmentOnlineRate": "Equipment online rate",
            "xiXiang_EquipmentOfflineRate": "Equipment offline rate",
            "xiXiang_EquipmentFailureRate": "Equipment failure rate",
            "xiXiang_EquipmentAlarmRate": "Equipment alarm rate",

            "xiXiang_AlarmOverview": "Alarm Overview",
            "xiXiang_TotalNumberOfAlarms": "Total number of alarms",
            "xiXiang_AlarmsProcessed": "Alarms processed",
            "xiXiang_UnprocessedAlarms": "Unprocessed alarms",


            "xiXiang_Faultprofile": "Fault profile",
            "xiXiang_TotalNumberOfFaults": "Total number of faults",
            "xiXiang_Troubleshooting": "Troubleshooting",
            "xiXiang_UntreatedFaults": "Untreated faults",
        },

        //智慧消防地图 
        fireControl: {
            map_standardColor: 'Standard color',
            map_blue: 'Polar Night Blue',
            map_cyan: 'Indigo Blue',
            map_gray: 'Eminent Gray',
            map_black: 'Phantom Black',
            map_silver: 'Moonlight Silver',

            map_contacts: 'Contact person',
            map_contactsPhone: 'Contact phone',
            map_NameOfPlace: 'Site name',
            map_latitude: 'latitude',
            map_longitude: 'longitude',
            map_siteBasicTitle: 'Basic site information',
            map_deviceListTitle: 'Device list',
            map_deviceNum: 'code',
            map_deviceModelNum: ' type',
            map_mapStyle: 'Map style modification',
            map_goBacSitekNews: 'Return to site information',
            map_messageSite: 'Please select the site first!',
            map_siteEntity: 'Unit',
            map_unit: 'Building',
            map_pushTitle: 'Push record',
            map_alarmHandle: 'Handle',

            map_state: 'State',
            map_select: 'Selected',
            map_placePoint: 'Site Point',
            map_restPoint: 'Reset Point',
            map_confirmEditPoint: 'Confirm Edit Point',
            map_palceTIpes: 'Click on the floor ichnography to site the point and remember to save it',

            map_firemAlarmSystem: "map"

        },


        all: {
            deviceModel_typeName: 'Type Name',
            deviceModel_typeNameRule: 'Type Name cannot be empty',

            deviceCheck_tips: 'Confirm import customers?',
            deviceCheck_normalDevcieSucc: 'Normal devices imported successfully!',
            deviceCheck_normalDevcieLose: 'Failed to import normal devices!',
            deviceCheck_selectDevice: 'Please select the unit for normal devices first!',
            deviceCheck_abnormalDevcieSucc: 'Abnormal devices imported successfully!',
            deviceCheck_abnormalDeviceLose: 'Failed to import abnormal devices!',
            deviceCheck_selectAbnormalDevcie: 'Please select the unit for normal devices first!',

            AlarmPlatform_deviceNo3D: 'Device not linked to 3D map',
            AlarmPlatform_devcieNoModel: 'Device not linked to 3D model',
            AlarmPlatform_alarmBanister_noCHeck: 'Alarm banister is idle, cannot be viewed',
            AlarmPlatform_noSpacing: 'Input should not contain spaces',
            AlarmPlatform_noDeviceType: 'No Device Type Data',
            AlarmPLatform_noAlarmLog: 'No Alarm Logs',

            DutyLogManage_messageLogsNoSpacing: 'Log content cannot be all spaces!',
            DutyLogManage_messageLog: 'Log content cannot be all spaces!',
            DutyLogManage_messageAddSucc: 'Added successfully!',
            DutyLogManage_messageAddLose: 'Failed to add!',

            entity_enttiyName: 'Unit',
            entity_enttiyNameRule: 'Please enter the unit name',
            entity_platformLogName: 'Platform Name',
            entity_platformLogNameRule: 'Please enter the platform name',
            entity_platformLogNameRule_noSpace: "Platform name cannot be empty",
            entity_clcikMapPla: 'Click to view devices, map displays units devices',
            entity_clearImp: 'Cancel successfully!',

            // DutyLogManage_
            // DutyLogManage_

            //用户管理
            userManage_loginNamePla: 'Account can be a single 11-digit phone number or a combination of letters and numbers',
            userManage_passwordPla: 'Enter password',
            userManage_UserNnamePla: 'Enter name',
            userManage_PhonePla: 'Enter contact number',
            userManage_genderPla: 'Select gender',
            userManage_rolePla: 'Select role',
            userManage_entityPla: 'Select affiliated unit',
            userManage_registerPla: 'Select login state',
            userManage_imgUpData: 'Select Upload File',
            userManage_imgTitle: 'Uploaded Inspection Images',
            userManage_superUser: 'Super Administrator',
            userManage_produceUser: 'Production Administrator',
            userManage_appletUser: 'applet Administrator',
            userManage_unitUser: 'Unit Administrator',
            userManage_addUserSucc: 'User added successfully',
            userManage_userExist: 'Account already exists',
            userManage_addUserLose: 'Failed to add user',


            // 安装点管理
            pointManage_pointAddrRule: 'Point address cannot be empty',
            pointManage_pointNameRule: 'Point name cannot be empty',

            // 数字大屏幕
            Digital_Online_rate: 'Online rate',
            Digital_mapCheckDevcie: 'check device',
            Digital_mapCheckFloor: 'check Devices',

            // 临时用户
            temporary_phoneChangPla: 'Confirm phone number change?',
            temporary_smsChangPla: 'Confirm SMS change?',

            // 绑定
            devcieBind_seleceMap: 'Select map site!',
            devcieBind_seleceMapLose: 'Choose existing site or create a new one!',

            // 报警记录
            alarmRecord_allAlarmState: 'All alarm statees',

            // 联动规则
            linkRule_openState: 'Confirm changing this on/off state?',

            noData_threeDModel: 'No 3D model Data',
            noDevcieData: 'No device data',

            video_start: 'Start',
            video_suspend: 'Pause',
            video_multiple: 'Playback Speed',
            video_control: ' Control',
            video_playVideo: 'Play',
            video_refresh: 'Refresh',
            video_dataName: 'File',
            video_messaeErr: 'Failed to retrieve playback! Please click the refresh button',
            video_palyBalck: 'Video Playback',
            videe_levelShift: 'Horizontal Movement',
            videe_verticalShift: 'Vertical Movement',
            vide_videoManipulation: 'Video Operation',
            vide_regionalSetting: 'Zone control',
            vide_clearCanvas: 'Clear Canvas',
            vide_saveCoordinate: 'Save Coordinate',
            vide_morePoints: 'You can set up to 6 points!',
            vide_leastPoints: 'It takes at least 3 points!！',
            vide_savePointsOk: 'The Settings are valid, ready to save!',
            vide_savePointsError: 'This area is invalid. Please reset it',
            video_loding: 'Loading Video List...',

            videoSys_enven: 'event',
            videoSys_img: 'image',
            videoSys_online: 'Online',
            videoSys_downline: 'Offline',
            videoSys_fault: 'Fault',
            videoSys_alarm: 'Alarm',
            videoSys_heartbeat: 'Heartbeat',
            videoSys_flameAlarm: 'Flame Alarm',
            videoSys_smokeAlarm: 'Smoke Alarm',
            videoSys_flameSomkeAlarm: 'Fire Detection',
            videoSys_electrocarAlarm: 'Electric Scooter Identification',
            videoSys_unknownEvent: 'Unknown Event',
            videoSys_heartbearEvent: 'Heartbeat Event',
            videoSys_faceRecognition: 'Face Recognition',

            videoSys_loading: 'Loading....',
            videoSys_devcieDownLin: 'Device Offline',
            videoSys_allVideoList: 'All Data Loaded',
            videoSys_videoControls: 'Operation',
            videoSys_title: 'Security Platform Video Surveillance System',
            videoSys_fullScreen: 'Fullscreen',
            videoSys_clearAll: 'Close All',
            videoSys_passage: 'Channel',
            videoSys_loadingTwo: 'Loading...',
            videoSys_quitFullScren: 'Exit Fullscreen',
            videoSys_playLose: 'Playback failed. Please delete and reselect the device',

            imgae_floor: 'Please modify the location firs',

            devcieDetails_smokeArgument: '433Ibm',
            devcieDetails_smokeHardware: 'Hardware',
            devcieDetails_smokeSoftWare: 'Software',
            devcieDetails_smokeBu: 'Disarm/Arm',
            devcieDetails_smokeSeparate: 'Normal/Tamper',
            devcieDetails_smokeAudio: 'Sound/Mute',
            devcieDetails_smokeErasureAudio: 'Unmuted/Muted',
            devcieDetails_smokeOpen: 'Valve Closed/Open',
            devcieDetails_smokeMessErr: 'Failed to Retrieve Smoke Detector Host Parameters',
            devcieDetails_smokeBind: 'bind',
            devcieDetails_smokeNoBind: 'not bind',
            devcieDetails_smokeIsBind: 'Is it bound',
            devcieDetails_smokeChangerAddr: 'Edit address and location',
            devcieDetails_smokeAddr: 'addr',
            devcieDetails_smokeAddrRule: 'Address is required',
            devcieDetails_smokeAddrPla: 'Please enter an address',
            devcieDetails_smokePlachangePoint: 'Please adjust the map location',
            devcieDetails_smokePointTip: 'Please drag the map marker to update the location',
            devcieDetails_smokeBian: 'edit',
            devcieDetails_smokePointChange: 'Please modify the point position!',

            mqtt_deviceAlarm: 'device alarm',
            mqtt_deviceAlarmDetail: 'alarm details',
            mqtt_checkVideo: 'video',
            mqtt_model: 'modules',

            time: 'time',

            site_businessHours: 'Business hours',
            site_businessState: 'Business state',
            site_businessStatePla: 'Select business state',
            site_businessStateOpt1: ' Business',
            site_businessStateOpt2: 'close',
            site_businessStateOpt3: 'subsist',
            header_closeBlackAlarmDevice: "Device Alarm Blacklist",
            header_alarmBell: 'Alarm sound',
            header_alarmRadio1: 'Alarm address',
            header_alarmRadio2: 'Alarm sound',

            exportAll_pla: 'Confirm Exporting All Data?',
            exportAll_btn: 'Export all',

            user_name: 'Owner',
            user_namePla: 'Enter owners name',
            user_phone: 'Phone',

            installationPersonnel_input: "enter install staff",

            user_phonePla: 'Enter owners phone',
            install_personnel: "install staff",

            community_input: "enter install community",
            community_name: "community name",

            install_time: 'install time',

            site_nameLength: 'The data length cannot exceed 50',

            upData_imgPla: 'Please upload file!'
        },

        batchBind: {
            detail: "Result",
            getDetailFailure: "Failed to query the binding result",
            addTitle: "Batch binding",
            entity: "Entity",
            message_importEntity: 'The Entity cannot be empty',
        }

    }
}